import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { Rebill } from '@/interfaces/rebill.interface';
import { useShipmentOrderContext } from '@/services/contexts';
import clsx from 'clsx';
import { Gap } from '@/components/gap';
import { Table, TableProps } from '@/components/table';

export const RebillTable = () => {
	const { t } = useTranslation();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder;

	const amountTotal = data?.rebills?.reduce((acc, curr) => acc + Number(curr.amount), 0);

	const columns: TableProps<Rebill>['columns'] = [
		{
			title: t(`Rebill Reason`),
			dataIndex: 'reason',
			width: '150px',
			HeaderRender: ({ title, style, className }) => (
				<div
					style={{ ...style, justifyContent: 'flex-start' }}
					className={className}
				>{`${title}`}</div>
			),
			CellRender: ({ value, style, className }) => (
				<div
					style={{ ...style, justifyContent: 'flex-start' }}
					className={className}
				>{`${value}`}</div>
			),
		},
		{
			title: t(`Status`),
			dataIndex: 'status',
			width: '150px',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>
					{value}
				</div>
			),
		},
		{
			title: t(`Description`),
			dataIndex: 'memo',
			HeaderRender: ({ title, style, className }) => (
				<div
					style={{ ...style, justifyContent: 'flex-start' }}
					className={className}
				>{`${title}`}</div>
			),
			CellRender: ({ value, style, className }) => (
				<div
					style={{ ...style, justifyContent: 'flex-start' }}
					className={className}
				>{`${value}`}</div>
			),
		},
		{
			title: t(`Rate`),
			dataIndex: 'amount',
			width: '150px',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>
					${value}
				</div>
			),
		},
		{
			title: t(`Amount`),
			dataIndex: 'amount',
			width: '150px',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={clsx(className, 'font-[700]')}>
					${value}
				</div>
			),
		},
	];

	return (
		<div>
			{/* header */}
			<div className='w-full flex justify-between items-center'>
				<div>
					<div className='h4-b text-theme-1-1-10 font-[700]'>{t('Rebill')}</div>
				</div>
			</div>

			<Gap height='16px' />

			<Table
				dataSource={data.rebills ?? []}
				columns={columns}
				commonHeaderClass='h-[32px] flex items-center body-4-b text-grey-01 bg-grey-05 font-[700]'
				commonCellClass='px-[16px] justify-end'
				commonBodyClass='h-[40px] flex items-center  body-4-r text-black font-[400]'
			/>

			<Divider />

			<div className='w-full flex flex-col items-end'>
				<div className='flex justify-end'>
					<div className='body-3-b text-grey-01 font-[700]'>{t('Total Amount')}</div>
					<Gap width='80px' />
					<div className='body-3-b text-black font-[700]'>${amountTotal}</div>
				</div>
			</div>
		</div>
	);
};
