import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import {
	GetAccountRes,
	GetBanksRes,
	GetPrepayOrdersProps,
	GetPrepayOrdersRes,
	GetTransactionLogRes,
	GetUserWallet,
} from './interface';

export const getPrepayOrders = async (props: GetPrepayOrdersProps) => {
	return await axios.get<GetPrepayOrdersRes>(`/wallet`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};

export const getBanks = async () => {
	return await axios.get<GetBanksRes>(`/wallet/banks`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};

export const getExchangeRate = async (currency: string) => {
	return await axios.get<number>(`wallet/exchange-rate?from=${currency}&to=USD`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};

export const addPrepayOrders = async (values) => {
	return await axios.post<GetPrepayOrdersRes>(`/wallet`, values, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};

export const getAccount = async () => {
	return await axios.get<GetAccountRes>(`/wallet/account`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};

export const getUserWallet = async () => {
	return await axios.get<GetUserWallet>(`/wallet/userWallet`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};

export const getTransactionLogs = async (params) => {
	return await axios.get<GetTransactionLogRes>(`wallet/transactionLogs`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params,
	});
};
