import { useState, useCallback, useMemo } from 'react';
import { Form, Input, Select, Button, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';
import '../../../login/components/login-form/index.less';
import { v3UserSignup } from '@/services/apis/v3-user-signup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { US_STATE_LIST, CANADA_STATE_LIST } from './data';
import { useGlobalContext } from '@/services/contexts/global';

const { Option } = Select;

interface SignupFormValues {
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	password: string;
	company_name: string;
	street: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
}

const INITIAL_VALUES = { country: 'United States' };

const Signup = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [selectedCountry, setSelectedCountry] = useState('United States');
	const [form] = useForm();
	const [loading, setLoading] = useState(false);
	const { userContext } = useGlobalContext();

	const onFinish = useCallback(
		async (values: SignupFormValues): Promise<void> => {
			setLoading(true);
			try {
				const data = {
					...values,
					v3_session_id: userContext.v3SessionManager.getSessionId(),
				};
				await v3UserSignup(data);
				message.success(t('Sign up successfully'));
				await userContext.fetchUserInfo(data.email, data.password);
				navigate('/');
			} catch (error) {
				message.error(error?.response?.data?.message || t('Failed to sign up'));
			} finally {
				setLoading(false);
			}
		},
		[navigate, t],
	);

	const handleLogin = useCallback(() => {
		navigate('/login');
	}, [navigate]);

	const isStateSelect = selectedCountry === 'United States' || selectedCountry === 'Canada';

	const handleCountryChange = useCallback((value: string) => {
		setSelectedCountry(value);
	}, []);

	const stateList = useMemo(
		() => (selectedCountry === 'United States' ? US_STATE_LIST : CANADA_STATE_LIST),
		[selectedCountry],
	);

	return (
		<div className='w-[530px] z-[1000] p-[25px_78px_28px_67px] rounded-[10px] bg-white'>
			<Form
				form={form}
				name='signup'
				requiredMark={false}
				className='w-full login-form'
				layout='vertical'
				onFinish={onFinish}
				initialValues={INITIAL_VALUES}
			>
				<div className='mb-[24px] text-center'>
					<div className='mb-[10px] h1-b text-black'>{t('Sign Up')}</div>
				</div>

				{/* 1. First Name, Last Name */}
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name='first_name'
							label={t('First Name')}
							rules={[{ required: true, message: t('Please input your first name!') }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name='last_name'
							label={t('Last Name')}
							rules={[{ required: true, message: t('Please input your last name!') }]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>

				{/* 2. Company Name */}
				<Form.Item
					name='company_name'
					label={t('Company Name')}
					rules={[{ required: true, message: t('Please input your company name!') }]}
				>
					<Input />
				</Form.Item>

				{/* 3. Country */}
				<Form.Item
					name='country'
					label={t('Country')}
					rules={[{ required: true, message: t('Please select your country!') }]}
				>
					<Select onChange={handleCountryChange}>
						<Option value='United States'>{t('United States')}</Option>
						<Option value='Canada'>{t('Canada')}</Option>
						<Option value='China'>{t('China')}</Option>
					</Select>
				</Form.Item>

				{/* 4. State, Zipcode */}
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name='state'
							label={t('State')}
							rules={[{ required: true, message: t('Please input your state!') }]}
						>
							{isStateSelect ? (
								<Select>
									{stateList.map((state) => (
										<Option value={state}>{state}</Option>
									))}
								</Select>
							) : (
								<Input />
							)}
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name='zipcode'
							label={t('Zipcode')}
							rules={[{ required: true, message: t('Please input your zipcode!') }]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>

				{/* 5. City */}
				<Form.Item
					name='city'
					label={t('City')}
					rules={[{ required: true, message: t('Please input your city!') }]}
				>
					<Input />
				</Form.Item>

				{/* 6. Street */}
				<Form.Item
					name='street'
					label={t('Street')}
					rules={[{ required: true, message: t('Please input your street address!') }]}
				>
					<Input />
				</Form.Item>

				{/* 7. Email, Phone */}
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name='email'
							label={t('Email')}
							rules={[
								{ required: true, message: t('Please input your email!') },
								{ type: 'email', message: t('Please input a valid email!') },
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name='phone'
							label={t('Phone')}
							rules={[{ required: true, message: t('Please input your phone number!') }]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>

				{/* 8. Password */}
				<Form.Item
					name='password'
					label={t('Password')}
					rules={[{ required: true, message: t('Please input your password!') }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item>
					<Button
						className='mb-[24px] p-[16px_20px_16px_20px] w-full h-full border-[0] rounded-[8px] bg-primary-regular body-3-sb text-white'
						type='primary'
						htmlType='submit'
						disabled={loading}
						loading={loading}
						block
					>
						{t('Sign Up')}
					</Button>
				</Form.Item>

				{/* have account? */}
				<div className='mt-[14px] text-center body-4-r text-grey-01'>
					{t('Already have an account?')}
					<span
						onClick={handleLogin}
						className='ml-[4px] text-primary-regular underline cursor-pointer'
					>
						{t('Login')}
					</span>
				</div>
			</Form>
		</div>
	);
};

export default Signup;
