import { useEffect, useRef, useState } from 'react';
import { useInViewport } from 'ahooks';
import _ from 'lodash';
import { CaretDownOutlined } from '@ant-design/icons';
import { CaretUpOutlined } from '@ant-design/icons';

export type TSortOrder = 'ascend' | 'descend' | null;

export type THeaderRender = (props: {
	title?: string;
	dataIndex?: string | string[];
	style?: React.CSSProperties;
	className?: string;
	onSort?: (sortOrder?: TSortOrder) => void;
	sortOrder?: TSortOrder;
}) => JSX.Element;

export type TCellRender<D> = (props: {
	value: string;
	record: D;
	style?: React.CSSProperties;
	className?: string;
	onRowClick?: (record: D) => void;
}) => JSX.Element;

export interface TableProps<D> {
	columns: {
		key?: string;
		title: string;
		dataIndex: string | string[];
		type?: 'data' | 'extra';
		/** allow 'flex' as a special value */
		width?: string;
		CellRender?: TCellRender<D>;
		HeaderRender?: THeaderRender;
		sorter?: boolean | ((a: D, b: D, sortOrder?: TSortOrder) => number);
	}[];
	dataSource: D[];
	className?: string;
	commonCellStyle?: React.CSSProperties;
	commonCellClass?: string;
	commonHeaderClass?: string;
	commonBodyClass?: string;
	CellRender?: TCellRender<D>;
	HeaderRender?: THeaderRender;
	onNextPage?: () => void;
	onRowClick?: (record: D) => void;
}

const BaseHeaderRender: THeaderRender = ({ title, style, className, onSort, sortOrder }) => (
	<div
		style={style}
		className={`${className} ${onSort ? 'flex items-center cursor-pointer' : ''}`}
		onClick={() => onSort?.()}
	>
		{title}
		{!!onSort && (
			<span className='flex flex-col items-center ml-2'>
				<CaretUpOutlined
					className={`text-xs cursor-pointer -mb-0.5 ${sortOrder === 'ascend' ? 'text-primary-regular' : ''}`}
				></CaretUpOutlined>
				<CaretDownOutlined
					className={`text-xs cursor-pointer -mt-0.5 ${sortOrder === 'descend' ? 'text-primary-regular' : ''}`}
				></CaretDownOutlined>
			</span>
		)}
	</div>
);

const BaseCellRender: TCellRender<any> = ({ value, record, style, className, onRowClick }) => (
	<div style={style} className={`${className}`} onClick={() => onRowClick?.(record)}>
		{value}
	</div>
);

export function Table<D>({
	columns,
	dataSource,
	className,
	commonCellStyle,
	commonCellClass,
	commonHeaderClass,
	commonBodyClass,
	CellRender,
	HeaderRender,
	onNextPage,
	onRowClick,
}: TableProps<D>) {
	const ref = useRef(null);
	const [inViewport] = useInViewport(ref);
	const [sortState, setSortState] = useState<{
		key: string | undefined;
		order: 'ascend' | 'descend' | null;
	}>({
		key: undefined,
		order: null,
	});

	useEffect(() => {
		if (inViewport) {
			onNextPage?.();
		}
	}, [inViewport]);

	const sortedDataSource = [...dataSource].sort((a, b) => {
		if (!sortState.key || !sortState.order) {
			return 0;
		}
		const column = columns.find((col) => col.key === sortState.key);
		if (!column?.sorter) {
			return 0;
		}
		if (typeof column.sorter === 'function') {
			return column.sorter(a, b, sortState.order);
		}
		const aValue = _.get(a, column.dataIndex, '');
		const bValue = _.get(b, column.dataIndex, '');
		return sortState.order === 'ascend'
			? String(aValue).localeCompare(String(bValue))
			: String(bValue).localeCompare(String(aValue));
	});

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: columns
					.map((c) => (!c.width || c.width === 'flex' ? 'minmax(auto, 1fr)' : c.width))
					.join(' '),
			}}
			className={className ?? ''}
		>
			{/* Header */}
			{columns.map(({ key, title, dataIndex, HeaderRender: ColumnHeaderRender, sorter }) => {
				const Render = ColumnHeaderRender ?? HeaderRender ?? BaseHeaderRender;
				const isSortable = !!sorter;

				return (
					<Render
						key={key ?? dataIndex.toString()}
						title={title}
						dataIndex={dataIndex}
						style={commonCellStyle ?? {}}
						className={`${commonCellClass ?? ''} ${commonHeaderClass ?? ''}`}
						onSort={
							isSortable
								? (sortOrder?: TSortOrder) => {
										setSortState((prev) => ({
											key: key ?? dataIndex.toString(),
											order:
												sortOrder ??
												(prev.key === (key ?? dataIndex.toString())
													? prev.order === 'ascend'
														? 'descend'
														: prev.order === 'descend'
															? null
															: 'ascend'
													: 'ascend'),
										}));
									}
								: undefined
						}
						sortOrder={key === sortState.key ? sortState.order : null}
					/>
				);
			})}

			{/* Body */}
			{sortedDataSource.flatMap((record, index) =>
				columns.map(({ key, dataIndex, type, CellRender: ColumnCellRender }) => {
					const Render = ColumnCellRender ?? CellRender ?? BaseCellRender;

					const value = type === 'extra' ? '' : _.get(record, dataIndex, '');

					return (
						<Render
							key={`${key ?? dataIndex.toString()}-${index}`}
							value={value}
							record={record}
							style={commonCellStyle ?? {}}
							className={`${commonCellClass ?? ''} ${commonBodyClass ?? ''} ${
								onRowClick ? 'cursor-pointer' : ''
							}`}
							onRowClick={(...args) => !!onRowClick && onRowClick(...args)}
						/>
					);
				}),
			)}

			<div ref={ref}></div>
		</div>
	);
}
