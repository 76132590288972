import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, message, Popconfirm, Space } from 'antd';
import { OrderFile } from '@/interfaces/file.interface';
import { Order } from '@/interfaces/order.interface';
import { deleteFile } from '@/services/apis';
import { downloadFile } from '@/services/apis/download-file';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

interface ActionsProps {
	order: Order;
	record: OrderFile;
	onDeleteSuccess: () => void;
}

export default ({ order, record, onDeleteSuccess }: ActionsProps) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const actions = [
		// {
		// 	icon: <EditOutlined rev />,
		// },
		{
			icon: <DownloadOutlined />,
			onClick: (record: OrderFile) =>
				downloadFile({
					path: {
						documentId: record.id,
						orderId: order.id,
					},
					other: {
						fileName: record.file_name,
					},
				}),
		},
		{
			icon: <DeleteOutlined />,
			onClick: (record: OrderFile) => {
				deleteFile({
					path: {
						documentId: record.id,
						orderId: order.id,
					},
				}).then(() => {
					onDeleteSuccess && onDeleteSuccess();
					message.success(t('delete successful'));
				});
			},
			enableConfirm: true,
			confirmConfig: {
				title: t('Delete the task'),
				description: t('Are you sure to delete this task?'),
			},
		},
	];

	return (
		<Space>
			{actions.map((action) =>
				action.enableConfirm ? (
					<Popconfirm
						{...action.confirmConfig}
						onConfirm={() => action.onClick && action.onClick(record)}
						okText={t('Yes')}
						cancelText={t('No')}
					>
						<Button type='text' icon={action.icon} />
					</Popconfirm>
				) : (
					<Button
						type='text'
						icon={action.icon}
						onClick={() => action.onClick && action.onClick(record)}
					/>
				),
			)}
		</Space>
	);
};
