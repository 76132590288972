import { getUserWalletTransactions } from '@/services/apis/get-user-wallet-transactions';
import { GetUserWalletTransactionsProps } from '@/services/apis/get-user-wallet-transactions/interface';
import { usePagination } from 'ahooks';
import { message } from '@/utils/message';

export const useGetUserWalletTransactions = (options?: Record<any, any>) => {
	const res = usePagination(
		async ({ current, pageSize }, props?: Partial<GetUserWalletTransactionsProps>) => {
			console.log(current, pageSize);
			const res = await getUserWalletTransactions({
				params: {
					page: current || props?.params?.page,
					per_page: pageSize || props?.params?.per_page,
					...props?.params,
				},
			});

			return {
				list: res?.data?.data ?? [],
				total: res?.data?.meta?.total,
			};
		},
		{
			// manual: true,
			onError: (error) => {
				// @ts-expect-error error.response type is not properly defined
				message.error(`${error.response?.data?.message ?? error.message}`);
			},
			...options,
		},
	);

	return {
		...res,
		data: res.data?.list ?? [],
	};
};
