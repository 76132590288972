import { WeightUnit } from '@/interfaces/weight-unit.enum';
import { Container } from './type';
import { LOADING_TYPE_TBD } from './constants';

export const emptyRecord: Partial<Container> = {
	number: undefined,
	type: undefined,
	package: '0 Cartons',
	weight: undefined,
	weight_unit: WeightUnit.KG,
	delivery_reference: undefined,
	urgent: false,
	seal_number: undefined,
	is_dg: false,
	is_overweight: false,
	is_soc: false,
	is_reefer: false,
	loading_type: LOADING_TYPE_TBD,
};
