import { v3Webhook } from '@/services/apis/v3-webhook';

const LOCAL_V3_SESSION_ID_KEY: string = 'v3_session_id';

class V3SessionManager {
	public getSessionId() {
		const sessionId: string | null = localStorage.getItem(LOCAL_V3_SESSION_ID_KEY);

		if (!sessionId) {
			return null;
		}

		try {
			return sessionId;
		} catch (error: any) {
			localStorage.removeItem(LOCAL_V3_SESSION_ID_KEY);
			return null;
		}
	}

	public setSessionId(sessionId: string) {
		localStorage.setItem(LOCAL_V3_SESSION_ID_KEY, sessionId);
	}

	public clearSessionId() {
		localStorage.removeItem(LOCAL_V3_SESSION_ID_KEY);
	}

	public v3Webhook(event: string, data: any) {
		const sessionId = this.getSessionId();

		if (!sessionId) {
			return;
		}

		v3Webhook({ event, data, session_id: sessionId });
	}
}

const v3SessionManager = new V3SessionManager();
export default v3SessionManager;
