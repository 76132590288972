import { baseURL } from '..';
import { DownloadLtlShipmentsFileProps } from './interface';
import qs from 'qs';
import { downloadFactory } from '../download-factory';

export const downloadLtlShipmentsFile = (props: DownloadLtlShipmentsFileProps) => {
	const {
		fileName,
		path: { ids, columns, ...extra },
	} = props;
	const url =
		baseURL +
		`tl/ltl/shipments/export?${qs.stringify({ ids, columns }, { arrayFormat: 'brackets' })}&${qs.stringify(extra, { arrayFormat: 'comma' })}`;

	return downloadFactory(url, fileName, { preferContentDisposition: true });
};
