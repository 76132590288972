import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {
	GetLTLAutofillFromPalletCalculatorRes,
	GetLTLAutofillProps,
	GetLTLAutofillRes,
} from './interface';

export * from './interface';

const BASE_URL = import.meta.env.VITE_DRAYAI_API_BASE_URL;
const FAST_API_BASE_URL = import.meta.env.VITE_DRAYAI_API_FAST_API_BASE_URL;
const SECRET_KEY = import.meta.env.VITE_AUTH_SECRET_KEY;

// 定义各个接口的URL
const URL = `${FAST_API_BASE_URL}/autofill_with_auth_verify`;
const URL_FOR_SHIPMENT = `${FAST_API_BASE_URL}/autofill_for_shipment_with_auth_verify`;
const URL_FROM_IMG = `${FAST_API_BASE_URL}/autofill_from_image_with_auth_verify`;
const URL_FAST_API_FROM_PALLET_CALCULATOR = `${FAST_API_BASE_URL}/ai/pallet/calculator/extract`;

// 新增的校验函数
const generateAuthData = (content: string) => {
	const timestamp = Math.floor(Date.now() / 1000).toString();
	const message = `${content}${timestamp}`;
	const signature = CryptoJS.HmacSHA256(message, SECRET_KEY).toString();
	return { timestamp, signature };
};

// 修改后的 getLTLAIAutofill 函数
export const getLTLAIAutofill = async (props: GetLTLAutofillProps) => {
	const { content } = props.data;
	const authData = generateAuthData(content);

	const response = await axios.post<GetLTLAutofillRes>(
		URL,
		{
			...props.data,
			...authData,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);

	return response;
};

export const getLTLAIAutofillForShipment = async (props: GetLTLAutofillProps) => {
	const { content } = props.data;
	const authData = generateAuthData(content);

	return await axios.post<GetLTLAutofillRes>(
		URL_FOR_SHIPMENT,
		{
			...props.data,
			...authData,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};

// 修改图片上传接口
export const getLTLAIAutofillFromImage = async (imageData: File) => {
	const formData = new FormData();
	formData.append('file', imageData);

	// 分块读取文件内容
	const chunkSize = 1024; // 每次处理1KB
	const fileArrayBuffer = await imageData.arrayBuffer();
	const fileBytes = new Uint8Array(fileArrayBuffer);
	let binary = '';

	for (let i = 0; i < fileBytes.length; i += chunkSize) {
		const chunk = fileBytes.slice(i, i + chunkSize);
		binary += String.fromCharCode(...chunk);
	}

	// 生成base64
	const fileBase64 = btoa(binary);

	// 生成签名
	const timestamp = Math.floor(Date.now() / 1000).toString();
	const message = `${fileBase64}${timestamp}`;
	const signature = CryptoJS.HmacSHA256(message, SECRET_KEY).toString();

	formData.append('timestamp', timestamp);
	formData.append('signature', signature);

	const response = await axios.post<GetLTLAutofillRes>(URL_FROM_IMG, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: accessTokenManager.getAccessToken(),
		},
		timeout: 30000,
	});

	return response;
};

export const getLTLAIAutofillFromPalletCalculator = async (props: GetLTLAutofillProps) => {
	const { content } = props.data;
	const authData = generateAuthData(content);

	return await axios.post<GetLTLAutofillFromPalletCalculatorRes>(
		URL_FAST_API_FROM_PALLET_CALCULATOR,
		{
			...props.data,
			...authData,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
