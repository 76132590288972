import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { userContext } from '@/services/contexts/user';

const AssistantMode = () => {
	const { token } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const tryLoginWithToken = useCallback(async () => {
		await userContext.fetchUserInfoByAssistantModeToken(token);
		navigate('/');
	}, [token, navigate]);

	useEffect(() => {
		if (!token) {
			message.error(t('Invalid token'));
			navigate('/login');
			return;
		}

		try {
			tryLoginWithToken();
		} catch (error) {
			console.error('Error setting token:', error);
			message.error(t('Failed to activate assistant mode'));
			navigate('/login');
		}
	}, [token, navigate, t, tryLoginWithToken]);

	return null;
};

export default AssistantMode;
