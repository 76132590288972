import accessTokenManager from '../contexts/user/access-token-manager';

export const downloadFactory = (
	url: string,
	fileName: string | number,
	config?: {
		preferContentDisposition?: boolean;
		preview?: boolean;
		previewTarget?: string;
	},
) => {
	let contentDisposition = '';
	return fetch(url, { headers: { Authorization: accessTokenManager.getAccessToken() } })
		.then((response) => {
			contentDisposition = response.headers.get('Content-Disposition') || '';
			return response.blob();
		})
		.then((blob) => {
			if (config?.preview) {
				// 这里不要 revokeObjectURL，否则导致预览页面无法下载
				// https://stackoverflow.com/questions/63109880/blob-createobjecturl-download-not-working-in-chrome
				const fileUrl = URL.createObjectURL(blob);
				window.open(fileUrl, config.previewTarget ? config.previewTarget : '_blank');
			} else {
				const fileUrl = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = fileUrl;
				a.download = config?.preferContentDisposition
					? contentDisposition.split('filename=')[1] || String(fileName)
					: String(fileName);
				a.style.display = 'none';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(fileUrl);
			}
		});
};
