import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { apiHooks } from '@/hooks/apis';
import NumberCard from './number-card';

const NumberCards = forwardRef<HTMLDivElement>((props, ref) => {
	const { t } = useTranslation();

	const { data: wallet } = apiHooks.useGetUsetWallet();

	const cardsInfo = [
		{
			title: t('Account Balance'),
			number: wallet?.balance || 0,
			tips: t('The total account balance'),
		},
		{
			title: t('Credit'),
			number: wallet?.credit || 0,
			tips: t(
				'The credit limit granted by the platform, which can be used to facilitate payments.',
			),
		},
		{
			title: t('Available Balance'),
			number: wallet?.available_balance || 0,
			tips: t('Available Balance = Account Balance + Credit'),
		},
	];
	return (
		<div className='flex flex-wrap w-full gap-4' ref={ref}>
			{cardsInfo.map((info) => (
				<NumberCard key={info.title} {...info} />
			))}
		</div>
	);
});

export default NumberCards;
