import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { Container } from '@/pages/search-rate/book/components/container-info/type';

export interface DraftOrCreateOrderProps {
	data: DraftOrCreateOrderPropsData;
}

export interface DraftOrCreateOrderRes {
	data: {
		id: number;
		mbl_number: string;
		uid: string;
		delivery_address: string;
		commodity: null;
		customer_memo: null;
		customer_reference_number: null;
		state: string;
		released_status: string;
		rate: {
			id: number;
			uid: string;
		};
		base_rate: string;
		fuel_surcharge: string;
		chassis_fee: null;
		chassis_split_fee: null;
		other_charge: null;
		service_fee: string;
		amount_total: number;
		contact_emails: string[];
		destination_type: number;
		live_or_drop: number;
		earliest_eta: string;
		container_delivered_counts: number;
		container_count: number;
		has_division: boolean;
		created_at: string;
		port_of_discharge_eta: string;
		port_of_discharge: Port;
		final_port: Port;
		final_port_eta: string;
		user: {
			id: number;
			name: string;
			email: string;
			company: {
				id: number;
				name: string;
			};
		};
		scenarios: Scenario[];
		possible_charges: PossibleCharge[];
		terminal: Terminal;
		warehouse: Warehouse;
		pickup_number_agent: null;
		containers: IContainer[];
		invoices: any[];
		messages: any[];
		files: any[];
	};
}

export enum State {
	DRAFT = 'draft',
	OPEN = 'open',
}

interface DraftOrCreateOrderPropsData {
	state: State;
	rate_id: number;
	rate_uid: string;

	port_of_discharge_id: number;
	port_of_discharge_eta: string;
	final_port_id: number;
	final_port_eta: string;
	terminal_id?: number;
	live_or_drop?: LiveOrDrop;
	warehouse_id: number;

	containers: Container[];
	pu_number_agent_id: number;

	mbl_number: string;
	commodity?: string;
	files: File[];
}

interface File {
	id: string;
	document_type?: string;
	container_numbers?: string[];
}

interface Port {
	id: number;
	name: string;
	active: boolean;
	latitude: number;
	longitude: number;
	has_ocean_port: null;
	has_ramp_port: null;
	special_notice: null;
	special_notice_cn: null;
}

interface Scenario {
	name: string;
	rates: RateDetail[];
	total_rate: string;
	description: string;
	possible_charges: PossibleChargeDetail[];
}

interface RateDetail {
	qty: number;
	name: string;
	rate: string;
	unit: string;
	amount: string;
}

interface PossibleChargeDetail extends PossibleCharge {
	cntr_size: number[];
	qty: number;
	amount: string;
	statement: string;
}

interface PossibleCharge {
	code: string;
	name: string;
	explanation: null | string;
	rate: string;
	unit: string;
	rate_text: string;
	free: null | string;
	min: null | string;
	max: null | string;
	probability: null | string;
}

interface Terminal {
	id: number;
	intermodal_region_id: number;
	name: string;
	firms: string;
	type: string;
	full_address: string;
	city: string;
	facility_type: string;
	street: string;
	zipcode: string;
	latitude: number;
	longitude: number;
}

interface Warehouse {
	id: number;
	name: string;
	code: null;
	zipcode: string;
	is_residential: boolean;
	address: string;
	city: City;
	country: string;
	customer_memo: null;
	appointment_by: number;
	created_at: string;
	updated_at: string;
	delivery_order_address: string;
	contacts: Contact[];
	user_id: number;
	user: {
		id: number;
		name: string;
		company: {
			id: number;
			name: string;
		};
	};
}

interface City {
	id: number;
	country: string;
	full_name: string;
	full_name_zipcode: string;
	zipcode: null;
	name: string;
	state: string;
	alias: null;
	latitude: number;
	longitude: number;
}

interface Contact {
	id: number;
	name: string;
	type: null;
	phone: string;
	email: string;
	remark: null;
}

interface IContainer {
	id: number;
	number: string;
	type: string;
	seal_number: null;
	package: string;
	weight: string;
	destination_type: number;
	delivery_reference: null;
	is_dg: boolean;
	is_overweight: boolean;
	is_soc: boolean;
	is_reefer: boolean;
	is_arrived_at_final_port: boolean;
	is_arrived_at_pod: boolean;
	lfd: null;
	ready_to_pickup_at: null;
	freight_released_at: null;
	customs_released_at: null;
	terminal_released_at: null;
	vessel_arrived_at: null;
	port_of_loading_etd: null;
	load_on_rail_at: null;
	port_of_discharge_eta: string;
	final_port_eta: string;
	actual_terminal_pickuped_at: null;
	empty_returned_at: null;
	order_dispatched_at: null;
	schedule_terminal_pickup_at: null;
	schedule_delivery_at: null;
	actual_delivered_at: null;
	actual_empty_returned_at: null;
	shipping_events: null;
	status: number;
	status_text: string;
	is_canceled: boolean;
	customer_status_logs: CustomerStatusLog[];
	remark_to_customer: null;
	live_or_drop: number;
	soc_return_location_id: null;
	soc_return_location: null;
	risks: any[];
}

interface CustomerStatusLog {
	customer_status: number;
	customer_status_name: string;
	updated_at: string | null;
}
