import drayageImg from '@/assets/drayage.png';
import logoImg from '@/assets/logo.png';
import '../login/index.less';
import Introduction from '../login/components/introduction';
import Signup from './components/signup-form';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '@/services/contexts';

const backgroundStyle = {
	background: `linear-gradient(118.01deg, #002B5B, #001A31)`,
	// backgroundImage: ``,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'bottom right',
};

const V3Signup = () => {
	const navigate = useNavigate();

	const { userContext } = useGlobalContext();
	const checkLegality = useCallback(() => {
		if (userContext.getUserInfo()) {
			navigate('/');
		}

		if (!userContext.v3SessionManager.getSessionId()) {
			navigate('/');
		}
	}, [navigate]);

	useEffect(() => {
		void checkLegality();
	}, [checkLegality]);

	return (
		<div className='w-screen' style={backgroundStyle}>
			<img src={logoImg} className=' absolute top-[43px] left-[47px]' />
			<img src={drayageImg} className=' absolute right-[60px] bottom-[42px]' />

			<div className='w-screen flex justify-center py-[128px]  gap-x-[128px]'>
				<Introduction />
				<Signup />
			</div>
		</div>
	);
};

export default V3Signup;
