import { useTranslation } from 'react-i18next';
import { Modal, ModalProps, Tabs, TabsProps, Checkbox, CheckboxProps } from 'antd';
import BlueCircle18pxIcon from '@/icons/blue-circle-18px-icon';
import { useState, ReactNode } from 'react';

const ListItem = ({ title, content }: { title: string; content: string | ReactNode }) => (
	<div className='grid grid-cols-[26px_1fr] grid-rows-[26px_1fr]'>
		<BlueCircle18pxIcon />
		<div>{title}</div>
		<div></div>
		<div>{content}</div>
	</div>
);

export const ServiceDetailModal = (props: ModalProps) => {
	const { t } = useTranslation();

	const [checked, setChecked] = useState(false);
	const onCheckboxChange: CheckboxProps['onChange'] = (e) => {
		setChecked(e.target.checked);
	};

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: t(`Attentions`),
			children: (
				<div className='flex flex-col items-start gap-[16px] min-h-[384px] max-h-[calc(100vh-210px)] overflow-y-auto'>
					<ListItem
						title={t(
							'Please inform the warehouse to print the BOL in advance and hand it over to the driver',
						)}
						content={t(
							'Kindly notify the pickup warehouse to print the Bill of Lading (BOL) in advance and hand it to the driver at the time of pickup. Otherwise, the driver will not collect the goods, which may incur a dry run fee.',
						)}
					/>
					<ListItem
						title={t('Delivery time is estimated')}
						content={t(
							'The delivery time in the order is an estimated time. It may be delayed due to uncontrollable factors such as traffic, weather, loading delays, and transit delays.',
						)}
					/>
					<ListItem
						title={t('When the goods are abnormal, it needs to be noted on the POD')}
						content={t(
							`Please remind the recipient: If there are any issues with the goods, such as damage or missing items, the recipient must note the specific details on the carrier's POD (Proof of Delivery) and keep a copy. Otherwise, no further claims can be processed. If there is only one POD, the recipient should make a note of the issue and take a photo for record-keeping.`,
						)}
					/>
					<ListItem
						title={t('Explanation of the POD aging period')}
						content={t(
							'After the delivery is completed, the POD (Proof of Delivery) will be returned by the carrier, which is expected to take 1-2 business days. We sincerely apologize for any inconvenience this may cause and kindly ask for your patience.',
						)}
					/>
					<ListItem
						title={t('Customer palletizing and packaging instructions')}
						content={t(
							"Please ensure that the outer packaging of the goods is sturdy, arranged neatly on the pallet, and tightly wrapped with film. Any damage occurring during transit due to issues with the product's packaging will not be compensated. Drivers may also refuse to pick up the goods due to the high risk of damage, which could result in delays and dry drun fees.",
						)}
					/>
					<ListItem
						title={t('Confirm cargo dimensions and weight information')}
						content={
							<>
								<div>
									{t(
										'Thank you for choosing our services! Please ensure the accuracy of the pallet dimensions and weight information to avoid the carrier reclassifying the goods, which could result in rebill. If such additional charges occur, the carrier will issue an inspection report, which is officially recognized by U.S. carriers, and use this report as the basis for charging you.',
									)}
								</div>
								<div>
									{t(
										'To effectively avoid additional charges, please make sure the cargo information is accurate and take photos of the measurement data when the goods are in the warehouse. If any disputes arise later, we can provide the measurement data as valid evidence at the earliest opportunity.',
									)}
								</div>
							</>
						}
					/>
					<ListItem
						title={t('Address Type Confirmation')}
						content={t(
							"Please ensure the correct pickup and delivery address type is selected. Any extra charges incurred due to selecting an incorrect address type will be the customer's responsibility, with the exact amount based on the carrier's supplemental invoice.",
						)}
					/>
					<ListItem
						title={t('Order Cancellation')}
						content={t(
							"To cancel an order, please notify our service team at least one business day before the scheduled pickup. Cancellation fees may apply, and the exact amount will be based on the carrier's cancellation invoice.",
						)}
					/>
				</div>
			),
		},
	];

	return (
		<Modal
			{...props}
			centered
			cancelButtonProps={{ ...props.cancelButtonProps, className: 'hidden' }}
			okButtonProps={{ ...props.okButtonProps, disabled: !checked }}
			okText={t('Continue')}
			width={'1000px'}
			footer={(_, { OkBtn }) => (
				<div className='flex items-center justify-between'>
					<Checkbox checked={checked} onChange={onCheckboxChange}>
						{t('I am aware of and agree to the above Service Description')}
					</Checkbox>
					<OkBtn />
				</div>
			)}
		>
			<div>
				<div className='h4-b text-black font-[700]'>{t(`Service Detail`)}</div>

				<Tabs defaultActiveKey='1' items={items} />
			</div>
		</Modal>
	);
};
