import { useTranslation } from 'react-i18next';
import { Form, FormInstance, InputNumber } from 'antd';
import SelectedCircle20pxIcon from '@/icons/selected-circle-20px-icon';
import UnselectedCircle20pxIcon from '@/icons/unselected-circle-20px-icon';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { InsuranceExclusionsDeclarationModal } from './insurance-exclusions-declaration-modal';

interface InsuranceProps {
	form: FormInstance;
	cargo_value_field_name: string;
	is_insurance_entrusted: boolean;
	premium: number;
	disableTitle?: boolean;

	onChangeIsInsuranceEntrusted: (value: boolean) => any;
}

export const InsuranceItem = observer(({ children }: { children: React.ReactNode }) => {
	return (
		<div className=' box-border p-[12px_12px_12px_8px] w-[486px] grid grid-cols-[20px_auto] grid-rows-[26px_auto] gap-x-[4px] gap-y-[4px] bg-primary-light-04 rounded-[4px] items-center'>
			{children}
		</div>
	);
});

export const Insurance = observer(
	({
		form,
		cargo_value_field_name,
		is_insurance_entrusted,
		premium,
		disableTitle,
		onChangeIsInsuranceEntrusted,
	}: InsuranceProps) => {
		const { t } = useTranslation();

		return (
			<div>
				{!disableTitle && (
					<div>
						<div className='h4-b text-[#333333]'>{t(`Insurance`)}</div>
						<Gap height='24px' />
					</div>
				)}
				<div className='flex gap-[20px]'>
					<InsuranceItem>
						<div></div>

						<div>{t(`Entrust DrayEasy to purchase insurance`)}</div>

						<div className='h-full flex flex-col justify-start'>
							{is_insurance_entrusted ? (
								<SelectedCircle20pxIcon className='grow-0' />
							) : (
								<UnselectedCircle20pxIcon
									className='grow-0 cursor-pointer'
									onClick={() => onChangeIsInsuranceEntrusted(true)}
								/>
							)}
						</div>

						<div className='h-full flex flex-col justify-start text-[11px] body-3-sb text-black font-normal'>
							<div>
								{t(
									`DrayEasy provides you with 5000 dollars of coverage for free. For the amount exceeding that, a certain insurance fee will be charged.`,
								)}
							</div>
							<span>
								{t(
									'The maximum insurable amount is 30000 dollars. Please fill in the actual value of the goods here for insurance. If the insured amount is higher than the actual value of the goods, there is a risk that the part exceeding the actual value will not be compensated by insurance after the goods are damaged or lost.',
								)}
								<InsuranceExclusionsDeclarationModal />
							</span>

							<div className='flex items-center gap-[12px]'>
								<div className='flex items-center gap-[4px]'>
									<div>{t(`Cargo Value`)}</div>
									<Form.Item
										name={cargo_value_field_name}
										rules={[
											() => ({
												validator: is_insurance_entrusted
													? (_, value) => {
															if (typeof value === 'number' && value > 0 && value <= 30000) {
																return Promise.resolve();
															}
															if (value > 30000) {
																return Promise.reject(
																	new Error(t(`The maximum insurable amount is 30000 dollars.`)),
																);
															}
															return Promise.reject(
																new Error(t('The cargo value should be greater than 0.')),
															);
														}
													: null,
											}),
										]}
										className={
											is_insurance_entrusted
												? 'ant-form-item-error-nowrap'
												: 'ant-form-item-error-noshow'
										}
									>
										<InputNumber
											disabled={!is_insurance_entrusted}
											controls={false}
											step={1}
											min={'100'}
											parser={(value) => (value ? value.replace(/\.\d*$/, '') : '')}
											className='w-[80px]'
										/>
									</Form.Item>
									<div>{t(`USD`)}</div>
								</div>

								<div className='flex items-center gap-[4px]'>
									<div>{t(`Premium`)}:</div>
									<div>{premium}</div>
									<div>{t(`USD`)}</div>
								</div>
							</div>
						</div>
					</InsuranceItem>

					{/* <InsuranceItem>
						<div></div>

						<div>{t(`Basic Carrier Insurance`)}</div>

						<div className='h-full flex flex-col justify-start'>
							{!is_insurance_entrusted ? (
								<SelectedCircle20pxIcon className='grow-0' />
							) : (
								<UnselectedCircle20pxIcon
									className='grow-0 cursor-pointer'
									onClick={() => {
										onChangeIsInsuranceEntrusted(false);
										form.setFieldValue(cargo_value_field_name, undefined);
									}}
								/>
							)}
						</div>

						<div className='h-full text-[11px] font-normal'>
							<span>{t(`Maximum limits of liability subject to carrier policy.`)} </span>
							<ExcludedFromInsuranceModal />
						</div>
					</InsuranceItem> */}
				</div>
			</div>
		);
	},
);
