import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { V3WebhookPayloadProps } from './interface';

export const v3Webhook = async (data: V3WebhookPayloadProps) => {
	return await axios.post(`/webhook`, data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
