import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import {
	CreateShipmentFromDraftShipmentProps,
	CreateShipmentFromDraftShipmentRes,
	CreateShipmentFromDraftShipmentParams,
} from './interface';

export * from './interface';

export const createShipmentFromDraftShipment = async (
	props: CreateShipmentFromDraftShipmentProps,
	params: CreateShipmentFromDraftShipmentParams,
) => {
	return await axios.post<CreateShipmentFromDraftShipmentRes>(
		`/tl/ltl/draftShipments/${params.id}/toShipment`,
		props.data,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
