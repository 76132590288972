import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterSpin from '@/components/center-spin';
import { useGlobalContext } from '@/services/contexts';
import { v3VerifySessionId } from '@/services/apis/v3-verify-session-id';
import { useTranslation } from 'react-i18next';

export default function VerifySessionId() {
	const navigate = useNavigate();
	const { sessionId } = useParams();
	const { userContext } = useGlobalContext();
	const { t } = useTranslation();

	const [errorMessage, setErrorMessage] = useState('');

	const verify = async () => {
		if (!sessionId) {
			return setErrorMessage(t('Session ID is required'));
		}

		try {
			await v3VerifySessionId(sessionId);
		} catch (error) {
			return setErrorMessage(error.response?.data?.message || t('Failed to verify session ID'));
		}

		userContext.v3SessionManager.setSessionId(sessionId);

		// check user if login
		await userContext.fetchUserInfoByAccessToken();

		if (userContext.getUserInfo()) {
			navigate('/');
		} else {
			navigate('/login');
		}
	};

	useEffect(() => {
		verify();
	}, [sessionId, navigate, userContext]);

	if (errorMessage) {
		return <div>{errorMessage}</div>;
	}

	return <CenterSpin size='large' />;
}
