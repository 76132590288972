import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from 'antd';
import { useBoolean } from 'ahooks';
import { Gap } from '@/components/gap';
import { CargoInsuranceExcludedCommoditiesTable } from '@/pages/ftl-get-rates/finalize-shipment-details/service-detail-modal/cargo-insurance-excluded-commodities-table';

export const InsuranceExclusionsDeclarationModal = (props: ModalProps) => {
	const { t } = useTranslation();

	const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

	const handleClose = () => close();

	return (
		<>
			<span className='text-primary-regular cursor-pointer' onClick={() => open()}>
				{t(`Insurance Notice`)}
			</span>
			{/* Hack for fullscreen */}
			<Modal
				{...props}
				open={isOpen}
				onCancel={handleClose}
				footer={null}
				width='1000px'
				style={{ top: 0, paddingBottom: 0 }}
				styles={{
					content: {
						borderRadius: 0,
						height: '100vh',
					},
					body: {
						height: '100%',
					},
				}}
			>
				<div className='w-full h-full flex flex-col'>
					<div className='h4-b text-black font-[700]'>{t(`Insurance Notice`)}</div>
					<Gap height='8px' />
					<div className='overflow-y-scroll'>
						<ol className='[&>li]:h4-b [&>li]:text-black [&>li>div:not(:first-child)]:body-3-sb [&>li>div:not(:first-child)]:text-black [&>li>div:not(:first-child)]:font-normal [&>li>ul]:body-3-sb [&>li>ul]:text-black [&>li>ul]:font-normal'>
							<li>
								<div>{t(`LTL Insurance Instructions`)}</div>
								<div>
									{t(
										'In the event of cargo damage, loss, or other incidents, DrayEasy assists customers in contacting the insurance company, initiating claims, and tracking progress. DrayEasy does not directly handle payments or compensation; all claim decisions and payouts are determined and executed by the insurance company in accordance with relevant regulations.',
									)}
								</div>
								<ul>
									<li>
										{t(
											'LTL Service: DrayEasy provides insurance coverage for shipments up to a maximum of $30,000. DrayEasy offers a $5 discount on the insurance premium for each LTL order. If the total cargo value is $5,000 or less, you can simply enter a value of $5,000 or below when placing the order, and you will not be required to pay any insurance fees.',
										)}
									</li>
									<li>
										{t(
											'If the declared cargo value exceeds $5,000, you will bear the cost of the insured amount exceeding $5,000 but less than $30,000. The system will automatically calculate the insurance fee at checkout. Please ensure that the insured value accurately reflects the actual cargo value.',
										)}
									</li>
									<li>
										{t(
											'For cargo valued over $30,000, we recommend that customers purchase insurance from a third-party insurance provider. If needed, our account managers can provide recommendations. However, purchasing and claim processing for such insurance will be managed directly between your company and the insurance provider.',
										)}
									</li>
								</ul>
							</li>
							<li>
								<div>{t('DrayEasy Insurance Fee Calculation')}</div>
								<ul>
									<li>{t('For coverage up to $5,000, no insurance fee is required.')}</li>
									<li>
										{t(
											'For coverage exceeding $5,000 but up to $10,000, the premium is calculated as (insured amount rounded to the nearest dollar) × 0.002.',
										)}
									</li>
									<li>
										{t(
											'For coverage exceeding $10,000 but up to $20,000, the premium is calculated as (insured amount rounded to the nearest dollar) × 0.0025.',
										)}
									</li>
									<li>
										{t(
											'For coverage exceeding $20,000 but up to $30,000, the premium is calculated as (insured amount rounded to the nearest dollar) × 0.003.',
										)}
									</li>
								</ul>
							</li>
							<li>
								<div>{t('Exclusions from Insurance Coverage')}</div>
								<CargoInsuranceExcludedCommoditiesTable />
							</li>
							<li>
								<div>{t('Insurance Claim Process')}</div>
								<ul>
									<li>
										{t(
											'After placing an order, remind the consignee that if any cargo is damaged or missing upon delivery, they must note the damage or loss on the carrier’s POD (Proof of Delivery). Claims cannot be processed without such documentation.',
										)}
									</li>
									<li>
										{t(
											'To initiate a claim with DrayEasy, your company must provide the following documents:',
										)}
										<ul>
											<li>
												{t(
													'For damaged cargo: Invoice for damaged goods, Invoice for the entire shipment, Overall cargo image, Detailed photos of damaged items, Photos of external and internal packaging.',
												)}
											</li>
											<li>
												{t(
													'For lost cargo: Invoice for lost goods, Invoice for the entire shipment.',
												)}
											</li>
										</ul>
									</li>
									<li>
										{t(
											'DrayEasy will submit the claim information to the insurance company and provide a response within 5 business days, followed by continuous updates on the claim progress.',
										)}
									</li>
									<li>
										{t(
											'The final payout amount will be determined by the insurance company based on the order details. Your company will need to provide bank account details for the insurance payout.',
										)}
									</li>
									<li>{t('Your company confirms receipt of the insurance payout.')}</li>
								</ul>
							</li>
							<li>
								<div>{t('Important Notes')}</div>
								<div>
									{t(
										'Insurance claims must be reported within the following timeframes; otherwise, the insurance company will reject the claim:',
									)}
								</div>
								<ul>
									<li>
										{t(
											'For loss during commercial courier delivery: Report within 90 days from the date the shipping route information is created.',
										)}
									</li>
									<li>
										{t(
											'For loss during truck delivery: If lost in transit before final delivery, report within 30 days from the date of departure from the overseas warehouse. If lost after delivery is signed for, report within 15 days from receipt of the POD.',
										)}
									</li>
									<li>
										{t(
											'For cases involving theft, damage, water exposure, or discrepancies in the number of packages upon delivery, report within 48 hours. Immediate photo documentation is required for the insurance company to assess whether the claim falls under policy coverage.',
										)}
									</li>
								</ul>
								<div>
									{t(
										'Attention⚠：For loss claims exceeding ¥50,000 (RMB), report immediately so the insurance company can dispatch a local inspector for on-site assessment (including unclaimed packages in courier shipping).',
									)}
								</div>
							</li>
							<li>
								<div>{t('Insurance Payout Calculation Rules')}</div>
								<ul>
									<li>
										<div>{t('Payout Based on Average Insured Value Per Pallet:')}</div>
										<ul>
											<li>
												{t(
													'Formula: Payout Amount = (Average insured amount per pallet) × (Number of lost/damaged pallets)',
												)}
											</li>
											<li>
												{t(
													'Example: A customer ships 10 pallets with a total insured amount of $10,000. If 1 pallet is lost or damaged, the maximum payout is $1,000.',
												)}
											</li>
										</ul>
									</li>
									<li>
										<div>{t('Payout Based on Average Insured Value Per Carton:')}</div>
										<ul>
											<li>
												{t(
													'Formula: Payout Amount = (Average insured amount per carton) × (Number of lost/damaged cartons)',
												)}
											</li>
											<li>
												{t(
													'Example: A customer ships 100 cartons with a total insured amount of $10,000. If 1 carton is lost or damaged, the maximum payout is $100.',
												)}
											</li>
										</ul>
									</li>
								</ul>
								<div>
									{t(
										'Attention⚠: If the actual cargo value per pallet exceeds the average insured amount per pallet, the maximum payout is still limited to the average insured amount per pallet. For example: If a shipment contains 10 pallets insured for $10,000, but the actual value per pallet is $2,000, then: If 1 pallet is lost/damaged, the maximum payout remains $1,000. To receive full coverage of $2,000 per pallet, the customer should have insured the shipment for $20,000.',
									)}
								</div>
							</li>
							<li>
								<div>{t('Acknowledgment and Confirmation')}</div>
								<div>
									{t(
										'By placing an order, the customer acknowledges and agrees to the insurance and claims policies outlined above. The customer commits to providing accurate cargo values when placing an order and will cooperate with DrayEasy in providing necessary documentation for insurance claims, following the established claims process.',
									)}
								</div>
							</li>
						</ol>
					</div>
				</div>
			</Modal>
		</>
	);
};
