import {
	getLTLAIAutofill,
	getLTLAIAutofillForShipment,
	getLTLAIAutofillFromImage,
	getLTLAIAutofillFromPalletCalculator,
} from '@/services/apis';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useGetLTLAutofill = (options?: Record<any, any>) => {
	const res = useRequest(getLTLAIAutofill, {
		manual: true,
		onError: (error) => {
			// @ts-expect-error xxx
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data,
	};
};

export const useGetLTLAutofillForShipment = (options?: Record<any, any>) => {
	const res = useRequest(getLTLAIAutofillForShipment, {
		manual: true,
		onError: (error) => {
			// @ts-expect-error xxx
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data,
	};
};

export const useGetLTLAutofillFromImage = (options?: Record<any, any>) => {
	const res = useRequest(getLTLAIAutofillFromImage, {
		manual: true,
		onError: (error) => {
			// @ts-expect-error xxx
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data,
	};
};

export const useGetLTLAutofillFromPalletCalculator = (options?: Record<any, any>) => {
	const res = useRequest(getLTLAIAutofillFromPalletCalculator, {
		manual: true,
		onError: (error) => {
			// @ts-expect-error xxx
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data,
	};
};
