import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetDraftShipmentsProps, GetDraftShipmentsRes } from './interface';

export const getDraftShipments = async (props: GetDraftShipmentsProps) => {
	return await axios.get<GetDraftShipmentsRes>(`/tl/ltl/draftShipments`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
