import axios from '../axios';

interface V3UserSignupProps {
	email: string;
	password: string;
	first_name: string;
	last_name: string;
	phone: string;
	company_name: string;
	street: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
}

export const v3UserSignup = async (props: V3UserSignupProps) => {
	return await axios.post('/v3UserSignup', props, {
		// headers: {
		// 	Authorization: accessTokenManager.getAccessToken(),
		// },
	});
};
