import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { isURLSearchParamsValid } from '@/utils/is-search-params-valid';
import valueTransfer from '@/utils/value-transfer';
import { SearchRateForm } from '../interface';
import { neededSearchParams } from '../needed-search-params';

export default () => {
	const [searchParams] = useSearchParams();
	const searchStr = window.location.search;

	// 直接解析 search 后的对象
	const parsedSearchParams = useMemo(
		() => qs.parse(searchStr, { ignoreQueryPrefix: true }),
		[searchParams],
	);

	// 判断 search 是否满足搜索条件
	const isSearchRatesParamsValid = useCallback(
		(params?: any) => {
			return isURLSearchParamsValid(params ?? parsedSearchParams, neededSearchParams);
		},
		[parsedSearchParams],
	);

	// 将 search 对象转换数据类型
	const parseCleanSearchParams = useCallback(
		(
			obj: any,
		): Pick<
			SearchRateForm,
			| 'intermodal_region_id'
			| 'to_city_id'
			| 'cntr_size'
			| 'cargo_types'
			| 'is_residential'
			| 'return_address_zipcode'
			| 'weight'
			| 'weight_unit'
		> & {
			order_id: string;
			not_search_if_had: boolean;
		} => {
			const {
				intermodal_region_id,
				to_city_id,
				cntr_size,
				cargo_types,
				is_residential,
				return_address_zipcode,
				weight,
				weight_unit,
				order_id,
				not_search_if_had,
			} = obj;

			const params = {
				intermodal_region_id: undefined,
				to_city_id: undefined,
				cntr_size: undefined,
				cargo_types: undefined,
				is_residential: undefined,
				return_address_zipcode: undefined,
				weight: undefined,
				weight_unit: undefined,
				order_id: undefined,
				not_search_if_had: undefined,
			};

			valueTransfer(params, 'intermodal_region_id', intermodal_region_id, Number);
			valueTransfer(params, 'to_city_id', to_city_id, Number);
			valueTransfer(params, 'cntr_size', cntr_size, Number);
			valueTransfer(params, 'cargo_types', cargo_types, (cargo_types: string[]) =>
				cargo_types.map((cargo) => Number(cargo)),
			);
			valueTransfer(params, 'is_residential', is_residential, (v: string) => v === 'true');
			valueTransfer(params, 'return_address_zipcode', return_address_zipcode, String);
			valueTransfer(params, 'weight', weight, Number);
			valueTransfer(params, 'weight_unit', weight_unit, String);

			valueTransfer(params, 'order_id', order_id, (v) => (v ? String(v) : v));

			valueTransfer(params, 'not_search_if_had', not_search_if_had, (v: string) => v === 'true');

			return params;
		},
		[],
	);

	const cleanedSearchParams = useMemo(
		() => parseCleanSearchParams(parsedSearchParams),
		[parseCleanSearchParams, parsedSearchParams],
	);

	return {
		cleanedSearchParams,
		parseCleanSearchParams,
		isSearchRatesParamsValid,
	};
};
