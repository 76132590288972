import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetDraftShipmentProps, GetDraftShipmentRes } from './interface';

export const getDraftShipment = async (props: GetDraftShipmentProps) => {
	return await axios.get<GetDraftShipmentRes>(
		`/tl/ltl/draftShipments/${props.path.draftShipmentId}`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
