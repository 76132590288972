const RANGES = [
	{ min: 5001, max: 6000, value: 6000 },
	{ min: 6001, max: 7000, value: 7000 },
	{ min: 7001, max: 8000, value: 8000 },
	{ min: 8001, max: 9000, value: 9000 },
	{ min: 9001, max: 10000, value: 10000 },
	// 千分之2.5
	{ min: 10001, max: 11000, value: 11000 },
	{ min: 11001, max: 12000, value: 12000 },
	{ min: 12001, max: 13000, value: 13000 },
	{ min: 13001, max: 14000, value: 14000 },
	{ min: 14001, max: 15000, value: 15000 },
	{ min: 15001, max: 16000, value: 16000 },
	{ min: 16001, max: 17000, value: 17000 },
	{ min: 17001, max: 18000, value: 18000 },
	{ min: 18001, max: 19000, value: 19000 },
	{ min: 19001, max: 20000, value: 20000 },
	// 千分之3
	{ min: 20001, max: 21000, value: 21000 },
	{ min: 21001, max: 22000, value: 22000 },
	{ min: 22001, max: 23000, value: 23000 },
	{ min: 23001, max: 24000, value: 24000 },
	{ min: 24001, max: 25000, value: 25000 },
	{ min: 25001, max: 26000, value: 26000 },
	{ min: 26001, max: 27000, value: 27000 },
	{ min: 27001, max: 28000, value: 28000 },
	{ min: 28001, max: 29000, value: 29000 },
	{ min: 29001, max: 30000, value: 30000 },
];

const FREE_PREMIUM = 5;
const FREE_PERMIUM_CARGO_VALUE = 5000;
const INSURANCE_DEBUCTIBLE_POLICY__5000_TO_10000_RATE = 0.002;
const INSURANCE_DEBUCTIBLE_POLICY_10000_TO_20000_RATE = 0.0025; // 千2.5，零免赔（单票10000-20000美元（含））
const INSURANCE_DEBUCTIBLE_POLICY_20001_TO_30000_RATE = 0.003; // 保费大于等于20001元，小于等于30000元，保费为千分之3

const getRate = (cargoValue: number): number => {
	if (cargoValue <= 10000) {
		return INSURANCE_DEBUCTIBLE_POLICY__5000_TO_10000_RATE;
	}

	if (cargoValue <= 20000) {
		return INSURANCE_DEBUCTIBLE_POLICY_10000_TO_20000_RATE;
	}

	return INSURANCE_DEBUCTIBLE_POLICY_20001_TO_30000_RATE;
};

export default (cargoValue: number): number => {
	const _cargoValue = Math.ceil(cargoValue);

	if (_cargoValue <= FREE_PERMIUM_CARGO_VALUE) {
		return 0;
	}

	const range = RANGES.find((range) => _cargoValue >= range.min && _cargoValue <= range.max);

	if (range) {
		return range.value * getRate(_cargoValue) - FREE_PREMIUM;
	} else {
		return 0;
	}
};
