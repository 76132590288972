import { WeightUnit } from '@/interfaces/weight-unit.enum';
import calcWeight from './calc-weight';

export const OW_20_FEET_STANDARD_LBS = 38000;
export const OW_20_FEET_REEFER_LBS = 36000;
export const OW_40_FEET_STANDARD_LBS = 44000;
export const OW_40_FEET_REEFER_LBS = 42000;
export const OW_45_FEET_STANDARD_LBS = 42000;
export const OW_45_FEET_REEFER_LBS = 40000;
export const OW_53_FEET_STANDARD_LBS = 50000;

export default (isReefer: boolean, size: number, weight: number, unit: WeightUnit) => {
	weight = calcWeight(weight, unit, WeightUnit.LB);

	switch (size) {
		case 20:
			return isReefer ? weight >= OW_20_FEET_REEFER_LBS : weight >= OW_20_FEET_STANDARD_LBS;
		case 40:
			return isReefer ? weight >= OW_40_FEET_REEFER_LBS : weight >= OW_40_FEET_STANDARD_LBS;
		case 45:
			return isReefer ? weight >= OW_45_FEET_REEFER_LBS : weight >= OW_45_FEET_STANDARD_LBS;
		default:
			break;
	}

	return false;

	//deprecated
	// return isReefer
	// 	? size >= 40
	// 		? weight >= 40000
	// 		: weight >= 34000
	// 	: size >= 40
	// 	? weight >= 44000
	// 	: weight >= 38000;
};
