import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, Table, TableProps, Tooltip } from 'antd';
import { UserWalletTransaction } from '@/interfaces/user-wallet-transaction';
import '@/styles/no-cell-border-table.less';
import usePrepayOrderParams from '../hooks/use-prepay-order-params';
import { useMemoizedFn, useMount, useSize } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';

const Transaction = observer(() => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = usePrepayOrderParams();

	const cardsSize = useSize(() =>
		document.querySelector('#main > div > div.flex.flex-wrap.w-full.gap-4'),
	);
	const tableHeaderSize = useSize(() => document.querySelector('.ant-table-header'));
	const tableScroll = useMemo<TableProps['scroll']>(
		() => ({
			scrollToFirstRowOnChange: true,
			x: 'max-content',
			y: `calc(100vh - ${272 + (cardsSize?.height ?? 0) + (tableHeaderSize?.height ?? 0)}px)`,
		}),
		[cardsSize, tableHeaderSize],
	);

	const {
		data: transactions,
		loading: userWalletTransactionsLoading,
		pagination,
		refresh: refreshUserWalletTransactions,
	} = apiHooks.useGetUserWalletTransactions();

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns = useMemo<TableProps<UserWalletTransaction>['columns']>(
		() => [
			{
				title: t('Transaction Date'),
				dataIndex: 'created_at',
				width: 160,
				fixed: 'left',
			},
			{
				title: t('Transaction Type'),
				dataIndex: 'operation_type',
				width: 160,
			},
			{
				//单据号码
				title: t('Transaction Number'),
				dataIndex: 'reference_number',
				width: 160,
			},
			{
				title: t('Transaction Amount'),
				dataIndex: 'transaction_amount',
				width: 160,
			},
			{
				title: t('Account Balance'),
				dataIndex: 'wallet_balance',
				width: 160,
				render: (text, record) => {
					if (text < 0) {
						//您当前账户的余额，如果为负数，请您及时充值
						return (
							<Tooltip title={t('You have a negative balance, please recharge promptly')}>
								<span>{text}</span>
							</Tooltip>
						);
					}
					return <span>{text}</span>;
				},
			},
			{
				title: t('Credit Limit'),
				dataIndex: 'wallet_credit',
				width: 180,
			},
			{
				title: t('Available Balance'),
				dataIndex: 'available_balance',
				width: 180,
			},
			{
				title: t('Instruction'),
				dataIndex: 'memo',
				width: 180,
			},
		],
		[t],
	);

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		changePagination(page, pageSize);
		changeSearchParams({ page, per_page: pageSize });
	});

	useMount(() => {
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 10);
	});

	return (
		<>
			<div className='flex-auto'>
				<Table
					className='no-cell-border-table'
					rowKey='id'
					columns={columns}
					dataSource={transactions}
					loading={userWalletTransactionsLoading}
					bordered
					pagination={false}
					scroll={tableScroll}
				></Table>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>
		</>
	);
});

export default Transaction;
