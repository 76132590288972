import { Image, Popover } from 'antd';
import { useEffect, useState, useMemo } from 'react';

interface CarrierColProps {
	record: any;
	value: string;
	isSelected: boolean;
}

const CarrierCol = ({ record, value, isSelected }: CarrierColProps) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	useEffect(() => {
		setIsPopoverOpen(isSelected);
	}, [isSelected]);

	// Memoize the carrier image and content to prevent reloading when isSelected changes
	const carrierContent = useMemo(
		() => (
			<div className='flex items-center gap-[18px]'>
				<div className='w-[90px] mx-1'>
					<Image
						className='scale-110'
						src={record.carrier_logo}
						fallback='https://public.drayeasy.com/tl_vendor_logos/NOLOGO.png'
						width='90px'
						preview={false}
					/>
				</div>

				<div className='flex flex-col'>
					<div className={`body-3-sb text-black`}>{value}</div>
				</div>
			</div>
		),
		[record.carrier_logo, value],
	);

	return (
		<>
			{record.carrier_introduction ? (
				<Popover
					open={isPopoverOpen}
					onOpenChange={setIsPopoverOpen}
					content={
						<div>
							<div className='body-3-sb whitespace-pre-wrap'>{record.carrier_introduction}</div>
						</div>
					}
					title={`Carrier Introduction Information`}
					trigger='hover'
				>
					{carrierContent}
				</Popover>
			) : (
				carrierContent
			)}
		</>
	);
};

export default CarrierCol;
