import { City } from './city.interface';
import { OrderFile } from './file.interface';
import { Invoice } from './invoice.interface';
import { Rebill } from './rebill.interface';
import { packageingTypes } from './quote.interface';

export const businessStatusMap = {
	// 10: 'Confirming',
	10: 'Order reviewing',
	20: 'Pending Pickup',
	30: 'In Transit',
	40: 'Delivered',
	50: 'Canceled',
	60: 'Delivered',
	70: 'Order Reject',
};

export interface ShipmentItem {
	description: string; // Commodity description * required
	class: string; // Freight class * required
	units: number; // Number of units * required
	total_weight: number; // Total weight * required
	weight_unit: 'lbs' | 'kg'; // lbs or kg * required
	dimension_unit: 'in' | 'cm'; // in or cm * required
	package_type: (typeof packageingTypes)[number]; // 见下表 * required
	pieces: number; // Number of pieces * required
	height: number; // Height * required
	length: number; // Length * required
	width: number; // Width * required
	nmfc?: string; // NMFC * optional
}

export interface TrackItem {
	status: string;
	timestamp: string;
}

export interface Shipment {
	id: number;
	uid: string;
	pickup_company_name: string;
	pickup_contact_name: string;
	pickup_contact_phone: string;
	pickup_contact_phone_ext?: string;
	pickup_contact_email: string;
	pickup_zipcode: string;
	pickup_city: City;
	pickup_address1: string;
	pickup_address2: string;
	pickup_special_request: string;
	destination_company_name: string;
	destination_contact_name: string;
	destination_contact_phone: string;
	destination_contact_phone_ext?: string;
	destination_contact_email: string;
	destination_zipcode: string;
	destination_city: City;
	destination_address1: string;
	destination_address2: string;
	destination_special_request: string;

	pro_number: string | null;

	pickup_accessorials: string[] | null;
	destination_accessorials: string[] | null;
	shipment_accessorials: string[] | null;

	customer_reference_number: string;
	pickup_number: string;
	pickup_instruction: string;
	dropoff_number: string;
	dropoff_instruction: string;
	// business_status: number;
	customer_status: number;
	sell_rate: string;
	is_insurance_entrusted: boolean;
	premium: string;
	cargo_value: string;
	pickup_open_time: string;
	pickup_close_time: string;
	destination_open_time: string;
	destination_close_time: string;
	estimated_pickup_date: string;
	estimated_delivery_date: string;
	actual_pickup_date: string | null;
	actual_delivery_date: string | null;
	delivery_open_time: string;
	delivery_close_time: string;
	status: number;
	reject_type: string | null;
	reject_reason: string | null;
	tracking_histories: TrackItem[];
	verification_status: number;
	user_id: number;
	quote_id: number;
	rate_id: number;
	vendor_carrier_name: string;
	vendor_shipment_id: string | null;
	vendor_tracking_url: string | null;
	vendor_status: string | null;
	quote_transit_days: number;
	special_request: string | null;
	vendor_documents: {
		id: number;
		name: string;
		file_name: string;
		type: string;
	}[];
	create_date: string;
	items: ShipmentItem[];
	user: {
		id: number;
		name: string;
	};

	invoices: Invoice[];
	rebills: Rebill[];
	documents: OrderFile[];

	bol_created_at: string; // Sep 12 2024 19:29PM

	// vendor_price: string;
	// margin_percent: number;
	// vendor_code: string;
	// vendor_name: string;
	// vendor_carrier_code: string;
	// vendor_bol_url: string;
	// vendor_palletlabel_url: string | null;
}
