import { useLTLContext } from '@/services/contexts';
import { useBoolean, useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { apiHooks } from '@/hooks/apis';
import { message } from '@/utils/message';
import { useGenerateAddress } from './use-generate-address';
import useLtlSearchRateParams from './use-ltl-search-rate-params';
import { pullQuoteRates } from '@/services/apis';
import { getDraftShipment } from '@/services/apis/get-draft-shipment';

export const useInitQuote = () => {
	const ltlContext = useLTLContext();

	const { parsedSearchParams } = useLtlSearchRateParams();

	const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(true);

	const { runAsync: getQuote } = apiHooks.useGetQuote({ manual: true });

	const { generateAddress } = useGenerateAddress();

	const initQuote = useMemoizedFn(async () => {
		try {
			startLoading();

			const res = await getQuote({
				path: {
					id: parsedSearchParams.quote_id,
				},
			});

			const quote = res.data.data;

			const pickupAddress = await generateAddress({
				city_name: quote.pickup_city_name,
				state_name: quote.pickup_state_name,
				zipcode: quote.pickup_zipcode,
			});

			const destinationAddress = await generateAddress({
				city_name: quote.destination_city_name,
				state_name: quote.destination_state_name,
				zipcode: quote.destination_zipcode,
			});

			if (pickupAddress) {
				ltlContext.pickupAddress = pickupAddress;
			}
			if (destinationAddress) {
				ltlContext.destinationAddress = destinationAddress;
			}

			ltlContext.stepOneFormValues = {
				pickup_date:
					parsedSearchParams.type === 'copy'
						? undefined
						: quote.pickup_date
							? dayjs(quote.pickup_date)
							: undefined, // 提货日期
				items:
					quote.items.length > 0
						? quote.items.map((item) => ({ ...item, id: Math.random().toString() }))
						: [],
				pickup_accessorials: quote.pickup_accessorials, // 提货附加服务
				destination_accessorials: quote.destination_accessorials, // 目的地附加服务
				shipment_accessorials: quote.shipment_accessorials, // 运输附加服务
			};
			ltlContext.pickUpAddressType = quote.pickup_address_type;
			ltlContext.destinationAddressType = quote.destination_address_type;
			ltlContext.calcItemFreightClass();

			if (parsedSearchParams.draft_shipment_id) {
				const response = await pullQuoteRates({ path: { quote_id: quote.id } });
				// 报价可能失效，需要更新
				ltlContext.selectedQuoteRate = response.data.data.rates.find(
					(r) => r.id == parsedSearchParams.rate_id,
				);
				const draftShipment = (
					await getDraftShipment({
						path: { draftShipmentId: parsedSearchParams.draft_shipment_id },
					})
				).data.data;
				console.log('response', response);
				console.log('quote', quote);
				console.log('ltlContext.selectedQuoteRate', ltlContext.selectedQuoteRate);
				// 回显页面 pickup address 相关内容
				ltlContext.pickupAddress = {
					...ltlContext.pickupAddress,
					name: draftShipment.pickup_company_name || ltlContext.pickupAddress.name || '',
					contacts: [
						// 我们不需要匹配完整的 contact 类型，只需要页面需要显示的部分
						// @ts-expect-error types not match
						{
							name: draftShipment.pickup_contact_name || '',
							email: draftShipment.pickup_contact_email || '',
							phone: draftShipment.pickup_contact_phone || '',
							phone_ext: draftShipment.pickup_contact_phone_ext || '',
						},
						...(ltlContext.pickupAddress.contacts ?? []),
					],
					address: draftShipment.pickup_address1 || ltlContext.pickupAddress.address || '',
					address2: draftShipment.pickup_address2 || ltlContext.pickupAddress.address2 || '',
					special_request:
						draftShipment.pickup_special_request || ltlContext.pickupAddress.special_request || '',
				};
				// 回显页面 destination address 相关内容
				ltlContext.destinationAddress = {
					...ltlContext.destinationAddress,
					name: draftShipment.destination_company_name || ltlContext.destinationAddress.name || '',
					contacts: [
						// 我们不需要匹配完整的 contact 类型，只需要页面需要显示的部分
						// @ts-expect-error types not match
						{
							name: draftShipment.destination_contact_name || '',
							email: draftShipment.destination_contact_email || '',
							phone: draftShipment.destination_contact_phone || '',
							phone_ext: draftShipment.destination_contact_phone_ext || '',
						},
						...(ltlContext.destinationAddress.contacts ?? []),
					],
					address:
						draftShipment.destination_address1 || ltlContext.destinationAddress.address || '',
					address2:
						draftShipment.destination_address2 || ltlContext.destinationAddress.address2 || '',
					special_request:
						draftShipment.destination_special_request ||
						ltlContext.destinationAddress.special_request ||
						'',
				};
				ltlContext.stepThreeFormValues = {
					customer_reference_number: draftShipment.customer_reference_number || undefined,
					pickup_number: draftShipment.pickup_number || undefined,
					pickup_instruction: draftShipment.pickup_instruction || undefined,
					dropoff_number: draftShipment.dropoff_number || undefined,
					dropoff_instruction: draftShipment.dropoff_instruction || undefined,

					pickup_open_time: draftShipment.pickup_open_time
						? dayjs(draftShipment.pickup_open_time, 'HH:mm')
						: undefined,
					pickup_close_time: draftShipment.pickup_close_time
						? dayjs(draftShipment.pickup_close_time, 'HH:mm')
						: undefined,

					destination_open_time: draftShipment.destination_open_time
						? dayjs(draftShipment.destination_open_time, 'HH:mm')
						: undefined,
					destination_close_time: draftShipment.destination_close_time
						? dayjs(draftShipment.destination_close_time, 'HH:mm')
						: undefined,
					estimated_delivery_date: draftShipment.estimated_delivery_date
						? dayjs(draftShipment.estimated_delivery_date)
						: undefined,

					is_insurance_entrusted: draftShipment.is_insurance_entrusted ?? true,
					// @ts-expect-error types not match
					cargo_value: draftShipment.cargo_value ?? undefined,

					isCheckAcknowledge: false,
				};
			}

			if (parsedSearchParams.type === 'copy') {
				ltlContext.step = 1;
			} else if (parsedSearchParams.draft_shipment_id) {
				ltlContext.quote = quote;
				// 如果报价未失效且已经选中了报价，则跳转到 step 3，否则跳转到 step 2
				ltlContext.step = ltlContext.selectedQuoteRate ? 3 : 2;
			} else {
				ltlContext.quote = quote;
				ltlContext.step = 2;
			}
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			endLoading();
		}
	});

	useMount(() => {
		if (!ltlContext.quote && parsedSearchParams.quote_id) {
			initQuote();
		} else {
			endLoading();
		}
	});

	return {
		loading,
	};
};
