import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	message,
	Row,
	Select,
	Space,
	Upload,
} from 'antd';
import { addPrepayOrders, getExchangeRate } from '@/services/apis';
import { InboxOutlined } from '@ant-design/icons';
import { apiHooks } from '@/hooks/apis';

const colSpan4 = { span: 4 };
const colSpan5 = { span: 5 };
const colSpan8 = { span: 8 };
const colSpan12 = { span: 12 };
const colSpan16 = { span: 16 };
const colSpan20 = { span: 20 };
const requiredRules = [{ required: true }];

const bankSelectFieldNames = { label: 'name', value: 'id' };

const width150Style: CSSProperties = { width: 150 };

const exchangeTypeFixed = 1;
const exchangeTypeFloating = 0;

const uploadFormItemGetFileListFromEvent = (e: any) => (Array.isArray(e) ? e : e.fileList);
const uploadBeforeUpload = () => false;

const AddFundsDrawerForm = ({
	onCancel,
	refreshPrepayOrders,
}: {
	onCancel: () => void;
	refreshPrepayOrders: () => void;
}) => {
	const { t } = useTranslation();

	const { data: banks } = apiHooks.useGetBanks();

	const exchangeTypeSelectOptions = [
		{ label: t('Floating Exchange Rate'), value: 0 },
		{ label: t('Fixed Exchange Rate'), value: 1 },
	];

	const [exchangeRates, setExchangeRates] = useState({ USD: 1 });
	useEffect(() => {
		if (!Array.isArray(banks) || banks.length === 0) {
			return;
		}
		Promise.allSettled(
			banks
				.filter((b) => b.currency !== 'USD')
				.map((b) =>
					getExchangeRate(b.currency).then((response) => {
						setExchangeRates((prev) => ({ ...prev, [b.currency]: response.data }));
					}),
				),
		);
	}, [banks]);

	const [form] = Form.useForm();

	const bank = Form.useWatch<number>('bank', form);
	const currentBankInfo = (banks ?? []).find((v) => v.id === bank);

	const amount = Form.useWatch<number>('amount', form);
	const exchangeType = Form.useWatch<number>('exchange_type', form);
	const exchangeRate = Form.useWatch('exchange_rate', form);

	const isExchangeRateInputReadOnly = exchangeType === exchangeTypeFloating;

	const cost = parseFloat(`${currentBankInfo?.custom_properties?.exchange_rate_cost || 0}`).toFixed(
		2,
	);
	const rate =
		exchangeType === exchangeTypeFixed
			? exchangeRate || 1
			: exchangeRates[currentBankInfo?.currency] || 1;
	const usd = (amount / rate || 0).toFixed(2);

	useEffect(() => {
		if (exchangeType === exchangeTypeFloating && currentBankInfo) {
			form.setFieldsValue({ exchange_rate: exchangeRates[currentBankInfo.currency] || 1 });
		}
	}, [exchangeType, currentBankInfo, exchangeRates, form]);

	const [isLoading, setIsLoading] = useState(false);
	const handleFormFinish = (values) => {
		setIsLoading(true);
		const formData = new FormData();
		(values.documents ?? []).forEach((d, i) => {
			formData.append(`documents[${i}]`, d.originFileObj);
		});
		formData.append('amount', values['amount']);
		formData.append('bank', values['bank']);
		formData.append('exchange_rate', values['exchange_rate'] || 1);
		formData.append('exchange_type', values['exchange_type']);
		addPrepayOrders(formData)
			.then((response) => {
				if (response) {
					refreshPrepayOrders();
					onCancel();
					form.resetFields();
				}
			})
			.catch((error) => {
				message.error(error?.message ?? error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Form
			form={form}
			labelCol={colSpan4}
			wrapperCol={colSpan20}
			autoComplete='off'
			onFinish={handleFormFinish}
		>
			{/* Bank Account */}
			<Form.Item
				labelCol={colSpan5}
				wrapperCol={colSpan12}
				name='bank'
				label={t('Payment method')}
				rules={requiredRules}
			>
				<Select
					placeholder={t('Select Bank Account')}
					options={banks}
					fieldNames={bankSelectFieldNames}
				/>
			</Form.Item>

			<Row>
				{/* Amount */}
				<Col span={12}>
					<Form.Item
						name='amount'
						labelCol={colSpan12}
						wrapperCol={colSpan12}
						label={t('Amount')}
						rules={requiredRules}
					>
						<InputNumber min={0} style={width150Style} prefix={currentBankInfo?.currency} />
					</Form.Item>
				</Col>

				{/* Exchange Type */}
				<Col span={12}>
					<Form.Item
						labelCol={colSpan12}
						wrapperCol={colSpan12}
						name='exchange_type'
						label={t('Exchange Type')}
						initialValue={0}
						rules={requiredRules}
					>
						<Select options={exchangeTypeSelectOptions} />
					</Form.Item>
				</Col>
			</Row>

			{currentBankInfo && currentBankInfo.currency !== 'USD' && (
				<Row>
					{/* Currency */}
					<Col span={8}>
						<Form.Item label={t('Currency')} labelCol={colSpan8} wrapperCol={colSpan16}>
							<Input readOnly prefix='USD' style={width150Style} value={usd} />
						</Form.Item>
					</Col>

					{/* Exchange Rate */}
					<Col span={8}>
						<Form.Item
							name='exchange_rate'
							label={t('Exchange Rate')}
							labelCol={colSpan12}
							wrapperCol={colSpan12}
						>
							<Input readOnly={isExchangeRateInputReadOnly} style={width150Style} />
						</Form.Item>
					</Col>

					{/* Exchange Loss */}
					<Col span={8}>
						<Form.Item label={t('Exchange Loss')} labelCol={colSpan12} wrapperCol={colSpan12}>
							<span className='ant-form-text'> {cost}</span>
						</Form.Item>
					</Col>
				</Row>
			)}

			{/* Documents */}
			<Form.Item
				name='documents'
				label={t('Doc of receipt')}
				rules={requiredRules}
				getValueFromEvent={uploadFormItemGetFileListFromEvent}
			>
				<Upload.Dragger beforeUpload={uploadBeforeUpload} listType='picture' maxCount={1}>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
					<p className='ant-upload-hint'>
						{t(
							'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
						)}
					</p>
				</Upload.Dragger>
			</Form.Item>

			<Divider />

			{/* Actions */}
			<div className='w-full flex justify-end'>
				<Space>
					<Button onClick={onCancel}>{t('Cancel')}</Button>
					<Button type='primary' htmlType='submit' loading={isLoading}>
						{t('Submit')}
					</Button>
				</Space>
			</div>
		</Form>
	);
};

export default AddFundsDrawerForm;
