import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Pagination, Table, TableProps } from 'antd';
import { Doc, PrepayOrder } from '@/interfaces/prepay-order.interface';
import { getDocUrl } from '@/services/apis/get-doc-url';
import '@/styles/no-cell-border-table.less';
import usePrepayOrderParams from '../hooks/use-prepay-order-params';
import { useMemoizedFn, useMount, useSize } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { downloadPrepayOrderReceipt } from '@/services/apis/download-prepay-order-receipt';

const RechargeHistory = observer(() => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = usePrepayOrderParams();

	const cardsSize = useSize(() =>
		document.querySelector('#main > div > div.flex.flex-wrap.w-full.gap-4'),
	);
	const tableHeaderSize = useSize(() => document.querySelector('.ant-table-header'));
	const tableScroll = useMemo<TableProps['scroll']>(
		() => ({
			scrollToFirstRowOnChange: true,
			x: 'max-content',
			y: `calc(100vh - ${272 + (cardsSize?.height ?? 0) + (tableHeaderSize?.height ?? 0)}px)`,
		}),
		[cardsSize, tableHeaderSize],
	);

	const {
		data: prepayOrders,
		loading: prepayOrdersLoading,
		pagination,
		refresh: refreshPrepayOrders,
	} = apiHooks.useGetPrepayOrders();

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const handleViewReceipt = useCallback((record: PrepayOrder) => {
		downloadPrepayOrderReceipt(
			{
				path: { id: record.id },
				other: { fileName: `receipt-${record.order_number}.pdf` },
			},
			{ preview: false },
		);
	}, []);

	const handleClick = useCallback(
		(record: PrepayOrder) => () => {
			handleViewReceipt(record);
		},
		[handleViewReceipt],
	);

	const handlePreview = useCallback(async (file: Doc, record: PrepayOrder) => {
		const resp = await getDocUrl(file.id.toString(), 'prepay_documents', record.id);
		const url = resp.data;
		window.open(url, '_blank');
	}, []);

	const handlePreviewClick = useCallback(
		(d: Doc, record: PrepayOrder) => () => {
			void handlePreview(d, record);
		},
		[handlePreview],
	);

	const columns = useMemo<TableProps<PrepayOrder>['columns']>(
		() => [
			{
				title: t('Order Number'),
				dataIndex: 'order_number',
				width: 160,
				fixed: 'left',
			},
			{
				title: t('Prepaid Amount'),
				dataIndex: 'prepaid_amount',
				width: 160,
			},
			{
				title: t('Prepaid Currency'),
				dataIndex: 'prepaid_currency',
				width: 160,
			},
			{
				title: t('Exchange Rate'),
				dataIndex: 'exchange_rate',
				width: 160,
			},
			{
				title: t('Exchange Rate Cost'),
				dataIndex: 'exchange_rate_cost',
				width: 180,
			},
			{
				title: t('Amount'),
				dataIndex: 'amount',
				width: 140,
				render: (v: number | bigint) => new Intl.NumberFormat('en-US').format(v),
			},
			{
				title: t('Bank Name'),
				dataIndex: 'bank_name',
				width: 160,
			},
			{
				title: t('Status'),
				dataIndex: 'status_name',
				width: 160,
				render: (v: string) => t(v),
			},
			{
				title: t('Doc of receipt'),
				dataIndex: 'docs',
				width: 160,
				render: (value, record) => (
					<>
						{record.documents.map((d: Doc) => (
							<Button
								type='link'
								className='px-0'
								key={d.id}
								onClick={handlePreviewClick(d, record)}
							>
								{d.file_name}
							</Button>
						))}
					</>
				),
			},
			{
				title: t('Order Date'),
				dataIndex: 'created_at',
				width: 200,
			},
			{
				key: 'action',
				title: t('Receipt'),
				render: (_, record) =>
					record.status_name == 'Approved' &&
					record.bank_currency == 'USD' && (
						<Button type='link' onClick={handleClick(record)}>
							{t('Download')}
						</Button>
					),
			},
		],
		[t, handleClick, handlePreviewClick],
	);

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		changePagination(page, pageSize);
		changeSearchParams({ page, per_page: pageSize });
	});

	useMount(() => {
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	return (
		<>
			<div className='flex-auto'>
				<Table
					className='no-cell-border-table'
					rowKey='id'
					columns={columns}
					dataSource={prepayOrders}
					bordered
					pagination={false}
					scroll={tableScroll}
				></Table>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>
		</>
	);
});

export default RechargeHistory;
