import { UserInfo } from '@/services/contexts/user/interface';
import { Button } from 'antd';
import { t } from 'i18next';
import { SearchRatesResData } from '@/services/apis/search-rates/interface';
import { useMemo } from 'react';
import DraftOrdersDrawer from '@/pages/order/components/view-draft-orders-button';

interface BookButtonProps {
	canSaveToGoFreight: boolean;
	savedToGofreight: boolean;
	user: UserInfo;
	selectedSearchData: SearchRatesResData;
	loading: boolean;
	saveToGoFreight: () => void;
	book: () => void;
}
const BookButton = ({
	canSaveToGoFreight,
	savedToGofreight,
	user,
	selectedSearchData,
	loading,
	saveToGoFreight,
	book,
}: BookButtonProps) => {
	const showBookButton = useMemo(() => {
		return !canSaveToGoFreight && !savedToGofreight && user?.can_order;
	}, [canSaveToGoFreight, savedToGofreight, user]);

	const showDraftOrderButton = useMemo(() => {
		return savedToGofreight;
	}, [savedToGofreight]);

	const showSaveToGoFreightButton = useMemo(() => canSaveToGoFreight, [canSaveToGoFreight]);

	const commonButtonStyles = 'border-0 text-white font-bold bg-[#FF6966]';

	return (
		<>
			{showBookButton && (
				<Button
					className={`${commonButtonStyles} w-28 h-7 text-sm`}
					disabled={!selectedSearchData || loading}
					onClick={book}
				>
					{t('Book')}
				</Button>
			)}
			{showDraftOrderButton && <DraftOrdersDrawer />}
			{showSaveToGoFreightButton && (
				<Button
					className={`${commonButtonStyles} h-6 mr-1 text-xs`}
					onClick={saveToGoFreight}
					disabled={!selectedSearchData || loading}
				>
					{loading ? t('Saving...') : t('Save to GoFreight')}
				</Button>
			)}
		</>
	);
};

export default BookButton;
