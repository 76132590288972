import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Checkbox,
	DatePicker,
	Divider,
	Form,
	FormInstance,
	Input,
	message,
	TimePicker,
} from 'antd';
import * as _ from 'lodash';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import { CreateShipmentPropsData, CreateShipmentRes } from '@/services/apis';
import { useLTLContext } from '@/services/contexts';
import { userContext } from '@/services/contexts/user';
import { useMemoizedFn } from 'ahooks';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { disabledEndTime, disabledStartTime } from '@/utils/disabled-time';
import { extractNumber } from '@/utils/extract-number';
import { FormItem } from '../../form-item';
import { formItemWrapper } from '../../form-item-wrapper';
import { AIAutofillBtn } from '../ai-autofill-btn';
import { Insurance } from '../insurance';
import './index.less';
import { ServiceDetailModal } from './service-detail-modal';
import { ShipmentDetailItem } from './shipment-detail-item';
import { SideMap } from './side-map';
import { useLTLStepThreeAIAutofill } from './use-ltl-step-three-ai-autofill';
import { useServiceDetailModal } from './use-service-detail-modal';
import { replaceChineseSymbol } from '@/utils/replace-chinese-symbol';
import useLtlSearchRateParams from '../../use-ltl-search-rate-params';
import { AxiosResponse } from 'axios';
const StepThreeForm = observer(({ form }: { form: FormInstance }) => {
	const { t } = useTranslation();

	const { parsedSearchParams } = useLtlSearchRateParams();

	const ltlContext = useLTLContext();

	// pick up address
	const [form1] = Form.useForm();

	// delivery address
	const [form2] = Form.useForm();

	// pickup window
	const [form3] = Form.useForm();

	// delivery window
	const [form4] = Form.useForm();

	const { runAsync: createShipment } = apiHooks.useCreateShipment();

	const { runAsync: createShipmentFromDraftShipment } =
		apiHooks.useCreateShipmentFromDraftShipment();

	const { runAsync: createWarehouseWithCheck } = apiHooks.useCreateWarehouseWithCheck();

	const { runAsync: updateWarehouse } = apiHooks.useUpdateWarehouse();

	const { handleAIAutofill } = useLTLStepThreeAIAutofill();

	const { isOpen, open, close, onClose, loading, setLoading } = useServiceDetailModal();

	const handleNext = useMemoizedFn(async () => {
		try {
			ltlContext.stepNextButtonProps.loading = true;

			if (!userContext.hasEnoughBalance(+ltlContext.selectedQuoteRate.rate)) {
				message.error(t('Insufficient wallet balance'));
				return;
			}

			await Promise.all([
				form.validateFields(),
				form1.validateFields(),
				form2.validateFields(),
				form3.validateFields(),
				form4.validateFields(),
			]);

			open();
		} catch (error) {
			console.error(`ltl step three handleNext error`, error);
		} finally {
			ltlContext.stepNextButtonProps.loading = false;
		}
	});

	const handleServiceDetailModalOk = async () => {
		setLoading(true);

		const data = {
			pickup_zipcode: ltlContext.pickupAddress.zipcode, // required
			pickup_city_id: ltlContext.pickupAddress.city.id, // City id 从【获取城市列表】接口中获取 | required
			pickup_company_name: ltlContext.pickupAddress.name, // Facility Name | required
			pickup_contact_name: ltlContext.pickupAddress.contacts?.[0]?.name, // Facility Contact Name | required
			pickup_contact_email: ltlContext.pickupAddress.contacts?.[0]?.email, // Contact Email | required
			pickup_contact_phone: extractNumber(ltlContext.pickupAddress.contacts?.[0]?.phone ?? ''), // Contact Phone | required
			pickup_contact_phone_ext: ltlContext.pickupAddress.contacts?.[0]?.phone_ext,
			pickup_address1: ltlContext.pickupAddress.address, // Street Name | required
			pickup_address2: ltlContext.pickupAddress.address2, // Apt, Suite | optional
			pickup_special_request: ltlContext.pickupAddress.special_request,
			pickup_address_type: ltlContext.pickUpAddressType,
			destination_zipcode: ltlContext.destinationAddress.zipcode, // required
			destination_city_id: ltlContext.destinationAddress.city.id, // City id 从【获取城市列表】接口中获取 | required
			destination_state: ltlContext.destinationAddress.city.state,
			destination_company_name: ltlContext.destinationAddress.name, // Facility Name | required
			destination_contact_name: ltlContext.destinationAddress.contacts?.[0]?.name, // Facility Contact Name | required
			destination_contact_email: ltlContext.destinationAddress.contacts?.[0]?.email, // Contact Email | required
			destination_contact_phone: extractNumber(
				ltlContext.destinationAddress.contacts?.[0]?.phone ?? '',
			), // Contact Phone | required
			destination_contact_phone_ext: ltlContext.destinationAddress.contacts?.[0]?.phone_ext,
			destination_address1: ltlContext.destinationAddress.address, // Street Name | required
			destination_address2: ltlContext.destinationAddress.address2, // Apt, Suite | optional
			destination_special_request: ltlContext.destinationAddress.special_request,
			destination_address_type: ltlContext.destinationAddressType,

			estimated_pickup_date: dayjs(ltlContext.stepOneFormValues.pickup_date).format('YYYY-MM-DD'),

			...ltlContext.stepThreeFormValues,
			pickup_open_time: dayjs(ltlContext.stepThreeFormValues.pickup_open_time).format('HH:mm'),
			pickup_close_time: dayjs(ltlContext.stepThreeFormValues.pickup_close_time).format('HH:mm'),
			destination_open_time: dayjs(ltlContext.stepThreeFormValues.destination_open_time).format(
				'HH:mm',
			),
			destination_close_time: dayjs(ltlContext.stepThreeFormValues.destination_close_time).format(
				'HH:mm',
			),

			items: ltlContext.stepOneFormValues.items.map((item) => {
				const newItem = { ...item };
				delete item['id'];
				return newItem;
			}),

			pickup_accessorials: ltlContext.stepOneFormValues.pickup_accessorials ?? [],
			destination_accessorials: ltlContext.stepOneFormValues.destination_accessorials ?? [],
			shipment_accessorials: ltlContext.stepOneFormValues.shipment_accessorials,

			quote_id: ltlContext.quote?.id,
			rate_id: ltlContext.selectedQuoteRate.id,

			// insurance
			is_insurance_entrusted: ltlContext.stepThreeFormValues.is_insurance_entrusted,
			cargo_value: ltlContext.stepThreeFormValues.cargo_value,
		} as CreateShipmentPropsData;

		let res: AxiosResponse<CreateShipmentRes, any>;
		if (parsedSearchParams.draft_shipment_id) {
			res = await createShipmentFromDraftShipment(
				{ data },
				{ id: parsedSearchParams.draft_shipment_id },
			);
		} else {
			res = await createShipment({ data });
		}

		Promise.allSettled([
			ltlContext.savePickupAddress &&
				Number.isInteger(ltlContext.pickupAddress.id) &&
				(ltlContext.pickupAddress.id === -1
					? createWarehouseWithCheck({
							data: {
								name: ltlContext.pickupAddress.name,
								city_id: ltlContext.pickupAddress.city.id,
								zipcode: ltlContext.pickupAddress.zipcode,
								address: ltlContext.pickupAddress.address,
								address2: ltlContext.pickupAddress.address2,
								special_request: ltlContext.pickupAddress.special_request,
								contacts: ltlContext.pickupAddress.contacts,
								appointment_by: 0,
							},
						})
					: updateWarehouse({
							path: {
								warehouseId: ltlContext.pickupAddress.id,
							},
							data: {
								name: ltlContext.pickupAddress.name,
								city_id: ltlContext.pickupAddress.city.id,
								zipcode: ltlContext.pickupAddress.zipcode,
								address: ltlContext.pickupAddress.address,
								contacts: ltlContext.pickupAddress.contacts,
								appointment_by: 0,
							},
						})),
			ltlContext.saveDestinationAddress &&
				Number.isInteger(ltlContext.destinationAddress.id) &&
				(ltlContext.destinationAddress.id === -1
					? createWarehouseWithCheck({
							data: {
								name: ltlContext.destinationAddress.name,
								city_id: ltlContext.destinationAddress.city.id,
								zipcode: ltlContext.destinationAddress.zipcode,
								address: ltlContext.destinationAddress.address,
								address2: ltlContext.destinationAddress.address2,
								special_request: ltlContext.destinationAddress.special_request,
								contacts: ltlContext.destinationAddress.contacts,
								appointment_by: 0,
							},
						})
					: updateWarehouse({
							path: {
								warehouseId: ltlContext.destinationAddress.id,
							},
							data: {
								name: ltlContext.destinationAddress.name,
								city_id: ltlContext.destinationAddress.city.id,
								zipcode: ltlContext.destinationAddress.zipcode,
								address: ltlContext.destinationAddress.address,
								contacts: ltlContext.destinationAddress.contacts,
								appointment_by: 0,
							},
						})),
		]).catch((error) => {
			console.error(`save address error`, error);
		});

		ltlContext.order = res.data.data;

		setLoading(false);

		onClose();
	};

	useEffect(() => {
		ltlContext.stepNextHandler = () => void handleNext();
	}, [form, handleNext]);

	useEffect(() => {
		form1.setFieldsValue({
			...ltlContext.pickupAddress,
		});
	}, [ltlContext.pickupAddress]);

	useEffect(() => {
		form2.setFieldsValue({
			...ltlContext.destinationAddress,
		});
	}, [ltlContext.destinationAddress]);

	useEffect(() => {
		form3.setFieldsValue({
			pickup_open_time: ltlContext.stepThreeFormValues.pickup_open_time,
			pickup_close_time: ltlContext.stepThreeFormValues.pickup_close_time,
		});
	}, [
		ltlContext.stepThreeFormValues.pickup_open_time,
		ltlContext.stepThreeFormValues.pickup_close_time,
	]);

	useEffect(() => {
		form4.setFieldsValue({
			destination_open_time: ltlContext.stepThreeFormValues.destination_open_time,
			destination_close_time: ltlContext.stepThreeFormValues.destination_close_time,
		});
	}, [
		ltlContext.stepThreeFormValues.destination_open_time,
		ltlContext.stepThreeFormValues.destination_close_time,
	]);

	useEffect(() => {
		ltlContext.stepNextButtonProps.disabled = false;
	}, []);

	useEffect(() => {
		ltlContext.stepThreeFormValues.estimated_delivery_date = ltlContext.selectedQuoteRate
			?.estimated_delivery_date
			? dayjs(ltlContext.selectedQuoteRate.estimated_delivery_date)
			: undefined;
	}, [ltlContext.selectedQuoteRate?.estimated_delivery_date]);

	return (
		<>
			{/* title */}
			<div className='flex items-baseline gap-[12px]'>
				<div className='h4-b text-theme-1-1-10'>{t(`Finalize shipment details`)}</div>
				<AIAutofillBtn handleAIAutofill={handleAIAutofill} />
			</div>

			{/* content */}
			<div className='flex justify-between'>
				{/* form */}
				<div className='w-[710px] flex flex-col'>
					<div className='pl-[12px] flex flex-col gap-[24px]'>
						{/* title */}
						<div>
							<Divider />
							<div className='h4-b text-[#333333]'>{t(`Reference numbers & instructions`)}</div>
						</div>
						<div className='flex flex-row gap-[12px]'>
							<div className='flex gap-[12px]'>
								<FormItem titleProps={{ content: t('Customer Reference Number') }}>
									<Form.Item name='customer_reference_number' normalize={replaceChineseSymbol}>
										<Input
											maxLength={255}
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[222px] h-[42px]`}
										/>
									</Form.Item>
								</FormItem>
							</div>
							<FormItem titleProps={{ content: t('Pickup Number') }}>
								<Form.Item name='pickup_number' normalize={replaceChineseSymbol}>
									<Input
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[222px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
							<FormItem titleProps={{ content: t('Droppff Number') }}>
								<Form.Item name='dropoff_number' normalize={replaceChineseSymbol}>
									<Input
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[222px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>
					</div>

					<Gap height='24px' />

					{/* title */}
					<div className='pl-[12px]'>
						<Divider />
						<div className='h4-b text-[#333333]'>{t(`Shipment details`)}</div>
					</div>

					<Gap height='16px' />

					<Form
						form={form1}
						validateMessages={{
							required: t(`Required`),
						}}
						onValuesChange={(changedValues, allValues) => {
							_.merge(ltlContext.pickupAddress, changedValues);
						}}
					>
						<ShipmentDetailItem
							type='pickup'
							titleIcon={<CircleBlueFillIcon />}
							borderColor='#C8E3FF'
							form={form1}
							extra={
								<div className='flex items-center gap-[12px]'>
									<div className='flex gap-[12px]'>
										<FormItem titleProps={{ content: t('Pickup Date'), required: true }}>
											<Input
												disabled
												value={
													ltlContext.stepOneFormValues.pickup_date
														? ltlContext.stepOneFormValues.pickup_date.format('MM/DD/YYYY')
														: ''
												}
												style={formItemWrapper.style}
												className={`${formItemWrapper.className} w-[140px] h-[42px]`}
											/>
										</FormItem>
									</div>

									<Form
										form={form3}
										onValuesChange={(changedValues, allValues) => {
											_.merge(ltlContext.stepThreeFormValues, changedValues);
										}}
										validateMessages={{
											required: t(`Required`),
										}}
										className='flex gap-[12px]'
									>
										<FormItem titleProps={{ content: t('Pickup Window'), required: true }}>
											<div className='flex items-center gap-[12px]'>
												<Form.Item name='pickup_open_time' rules={[{ required: true }]}>
													<TimePicker
														changeOnScroll
														needConfirm={false}
														format={'HH:mm'}
														suffixIcon={null}
														placeholder={t(`Pickup open time`)}
														minuteStep={15}
														showNow={false}
														disabledTime={disabledStartTime(
															ltlContext.stepThreeFormValues.pickup_close_time,
														)}
														className={`${formItemWrapper.className} w-[140px] h-[42px]`}
													/>
												</Form.Item>
												<Form.Item name='pickup_close_time' rules={[{ required: true }]}>
													<TimePicker
														changeOnScroll
														needConfirm={false}
														format={'HH:mm'}
														suffixIcon={null}
														placeholder={t(`Pickup close time`)}
														minuteStep={15}
														showNow={false}
														disabledTime={disabledEndTime(
															ltlContext.stepThreeFormValues.pickup_open_time,
														)}
														className={`${formItemWrapper.className} w-[140px] h-[42px]`}
													/>
												</Form.Item>
											</div>
										</FormItem>
									</Form>
								</div>
							}
						/>
					</Form>

					<Gap height='24px' />
					<Form
						form={form2}
						validateMessages={{
							required: t(`Required`),
						}}
						onValuesChange={(changedValues, allValues) => {
							_.merge(ltlContext.destinationAddress, changedValues);
						}}
					>
						<ShipmentDetailItem
							type='destination'
							titleIcon={<DestinationIcon />}
							borderColor='#FBBAB8'
							form={form2}
							extra={
								<div className='flex items-center gap-[12px]'>
									<div className='flex gap-[12px]'>
										<FormItem titleProps={{ content: t('Delivery Date'), required: true }}>
											<DatePicker
												disabled={!!ltlContext.stepThreeFormValues.estimated_delivery_date}
												suffixIcon={null}
												value={ltlContext.stepThreeFormValues.estimated_delivery_date}
												placeholder={t(`Delivery date`)}
												className={`${formItemWrapper.className} w-[222px] h-[42px]`}
												onChange={(value) => {
													ltlContext.stepThreeFormValues.estimated_delivery_date = value;
												}}
											/>
										</FormItem>
									</div>

									<Form
										form={form4}
										onValuesChange={(changedValues, allValues) => {
											_.merge(ltlContext.stepThreeFormValues, changedValues);
										}}
										validateMessages={{
											required: t(`Required`),
										}}
										className='flex gap-[12px]'
									>
										<FormItem titleProps={{ content: t('Delivery Window'), required: true }}>
											<div className='flex items-center gap-[12px]'>
												<Form.Item name='destination_open_time' rules={[{ required: true }]}>
													<TimePicker
														changeOnScroll
														needConfirm={false}
														format={'HH:mm'}
														suffixIcon={null}
														placeholder={t(`Delivery open time`)}
														minuteStep={15}
														showNow={false}
														disabledTime={disabledStartTime(
															ltlContext.stepThreeFormValues.destination_close_time,
														)}
														className={`${formItemWrapper.className} w-[222px] h-[42px]`}
													/>
												</Form.Item>
												<Form.Item name='destination_close_time' rules={[{ required: true }]}>
													<TimePicker
														changeOnScroll
														needConfirm={false}
														format={'HH:mm'}
														suffixIcon={null}
														placeholder={t(`Delivery close time`)}
														minuteStep={15}
														showNow={false}
														disabledTime={disabledEndTime(
															ltlContext.stepThreeFormValues.destination_open_time,
														)}
														className={`${formItemWrapper.className} w-[222px] h-[42px]`}
													/>
												</Form.Item>
											</div>
										</FormItem>
									</Form>
								</div>
							}
						/>
					</Form>

					<Gap height='24px' />

					<Divider />

					<div>
						<Insurance
							form={form}
							cargo_value_field_name='cargo_value'
							is_insurance_entrusted={ltlContext.stepThreeFormValues.is_insurance_entrusted}
							premium={ltlContext.premium}
							onChangeIsInsuranceEntrusted={(value) =>
								(ltlContext.stepThreeFormValues.is_insurance_entrusted = value)
							}
						/>
					</div>

					<div className='flex flex-col gap-[24px] acknowledgement'>
						<div>
							<Divider />
							<div className='h4-b text-[#333333]'>
								{t(`Acknowledgement`)}
								<span className=' text-additional-warning'>*</span>
							</div>
						</div>

						<div className='flex items-start gap-[8px]'>
							<Form.Item
								name='isCheckAcknowledge'
								rules={[
									{ required: true },
									{
										validator: (_, value) =>
											value ? Promise.resolve('') : Promise.reject(new Error(t('Required'))),
									},
								]}
								valuePropName='checked'
							>
								<Checkbox className={clsx(`red-check-box`)}>
									<div className='h-[16px] body-3-m text-additional-warning'>
										{t(
											`By checking this box, I acknowledge that I have reviewed the information above and accept full responsibility for any expenses incurred due to incorrect measurements.`,
										)}
									</div>
								</Checkbox>
							</Form.Item>
						</div>
					</div>
				</div>

				{/* side map */}
				<SideMap />
			</div>
			<ServiceDetailModal
				open={isOpen}
				closable
				onCancel={close}
				onOk={handleServiceDetailModalOk}
				okButtonProps={{ loading }}
			/>
		</>
	);
});

export const StepThree = observer(() => {
	const { t } = useTranslation();

	const [form] = Form.useForm();

	const ltlContext = useLTLContext();

	useEffect(() => {
		form.setFieldsValue(ltlContext.stepThreeFormValues);
	}, [ltlContext.stepThreeFormValues]);

	return (
		<Form
			form={form}
			validateMessages={{
				required: t(`Required`),
			}}
			className='box-border m-auto p-[48px_6px] w-full h-full flex flex-col overflow-x-hidden overflow-y-scroll step-form ltl-step-three'
			onValuesChange={(changedValues, allValues) => {
				_.merge(ltlContext.stepThreeFormValues, changedValues);
				console.log('allValues.cargo_value', allValues.cargo_value);
				console.log(
					'ltlContext.stepThreeFormValues.cargo_value',
					ltlContext.stepThreeFormValues.cargo_value,
				);
				console.log('form.getFieldsValue().cargo_value', form.getFieldsValue().cargo_value);
			}}
		>
			<StepThreeForm form={form} />
		</Form>
	);
});
